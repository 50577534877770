import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const PopupPage = () => {
    return (
        <div className='mx-auto justify-items-center flex'>
            <Popup className=' ' trigger={<button className=' '> login </button>} position="right center">
                <div>
                    <h1>Log in with your Whickr Account</h1>
                    <input type="text"placeholder='Email id' name="" id="" />
                </div>
            </Popup>
        </div>
    );
};

export default PopupPage;
