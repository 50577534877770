import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosRemoveCircle } from "react-icons/io";
import Autocomplete from "react-google-autocomplete";
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import axios from 'axios';

function Createadvart() {
    const [links, setLinks] = useState([]);
    const [remove, setRemove] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [Name, setName] = useState('')
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({})
    const [userId, setUserId] = useState()
    const [formData, setFormData] = useState({
        category: '',
        headline: '',
        sale_type: '',
        price: '',
        type: '',
        age: '',
        height: '',
        color: '',
        gender: '',
        discipline: '',
        breed: '',
        description: '',
        location: ''
    });

    const validate = () => {
        let tempErrors = {};
        if (!formData.category) tempErrors.category = "Category field required";
        if (!formData.headline) tempErrors.headline = "Headline field required";
        if (!formData.sale_type) tempErrors.sale_type = "Sale field required";
        if (!formData.price) tempErrors.price = "Price field required";
        if (!formData.type) tempErrors.type = "Type field required";
        if (!formData.age) tempErrors.age = "Age field required";
        if (!formData.height) tempErrors.height = "Height field required";
        if (!formData.color) tempErrors.color = "Color field required";
        if (!formData.gender) tempErrors.gender = "Gender field required";
        if (!formData.discipline) tempErrors.discipline = "Discipline field required";
        if (!formData.breed) tempErrors.breed = "Breed field required";
        if (!formData.description) tempErrors.description = "Description field required";
        if (!formData.location) tempErrors.location = "Location field required";
        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    };

    const navigate = useNavigate()
    const fileInputRef = useRef(null);

    const addLinkDiv = () => {
        setLinks([...links, '']);
    };

    const handleChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };


    const removeLink = (index) => {
        const newLinks = links.filter((_, i) => i !== index);
        setLinks(newLinks);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // Get the first file
        if (file) {
            setImage(URL.createObjectURL(file)); // Set the single image
        }
    };

    const handleRemoveImage = () => {
        setImage(null); // Remove the image
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const headerMenu = [
        { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
        { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
        { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
        { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
    ];


    const checkLoginInfo = () => {
        const userEmail = localStorage.getItem('userEmail');
        const userPassword = localStorage.getItem('userPassword');
        if (!userEmail && !userPassword) {
            navigate('/login')
        }
    }

    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');
        const userPassword = localStorage.getItem('userPassword');
        const userName = localStorage.getItem('username')
        const user_id = localStorage.getItem('user_id')
        setUserId(user_id)
        if (userEmail && userPassword) {
            setName(userName)
            setShowLoginPopup(true);
        } else {
            setShowLoginPopup(false);
        }
    }, []);

    const advApi = process.env.REACT_APP_CREATE_ADV_API_URL

    const submitHandler = (e) => {
        e.preventDefault()
        if (validate()) {
            console.log("Form submitted successfully");
        }
        const formData = new FormData();
        formData.append("user_id", userId)
        // formData.append("category", e.target.category.value)
        formData.append("title", e.target.headline.value)
        formData.append("sale_type", e.target.sale_type.value)
        formData.append("description", e.target.description.value)
        formData.append("price", e.target.price.value)
        formData.append("type", e.target.type.value)
        formData.append("age", e.target.age.value)
        formData.append("height", e.target.height.value)
        formData.append("colour", e.target.color.value)
        formData.append("gender", e.target.gender.value)
        formData.append("discipline", e.target.discipline.value)
        formData.append("breed", e.target.breed.value)
        formData.append("location", e.target.location.value)
        formData.append("contact_preferences", "hello")
        formData.append("video_links", links.join(','));
        formData.append("image", image);

        axios.post(advApi, formData)
            .then(
                (success) => {
                    console.log(success.data.status, 'success')
                    if (success.data.status == 'true') {
                        setFormData('');
                        setImage(null);
                    }
                }
            ).catch((err) => {
                console.log(err, 'err');
            }
            )
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    return (
        <>
            <div className='bg-gray-100'>
                <div className='container py-2 flex flex-wrap justify-between items-center'>
                    <ul className='flex flex-wrap gap-4 md:gap-8'>
                        {headerMenu.map((item, index) => (
                            <Link key={index} to={item.path} className='no-underline'>
                                <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                                    <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                                    <span className='text-black text-sm md:text-xl'>{item.link}</span>
                                </li>
                            </Link>
                        ))}
                    </ul>
                    <div className="relative inline-block text-left">
                        {showLoginPopup
                            ? <div
                                onMouseEnter={() => setIsOpen(true)}
                                onMouseLeave={() => setIsOpen(false)}
                                className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
                            >
                                <span className='text-xl md:text-3xl'><CiUser /></span>
                                <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
                                <p className='text-xl my-auto'><FaAngleDown /></p>
                                {isOpen && (
                                    <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                                            <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                                            <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                                            <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                                            <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                                            <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                            : <div
                                className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                                <Link to={'/login'} className='text-green-600 font-bold'>
                                    <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                                        Login
                                    </div>
                                </Link>
                            </div>
                        }
                    </div>
                </div>

                <div className=' max-w-screen-lg mx-auto px-4 lg:px-20 pb-12 pt-4'>
                    <div className='flex flex-col gap-2 lg:flex-row shadow-3xl'>
                        <div className='lg:w-4/12 bg-white rounded-xl p-4'>
                            <div className='lg:sticky top-[20px]'>
                                <div className='flex gap-4'>
                                    <h1 className='bg-[#377f63] w-[30px] h-[30px] flex items-center justify-center text-white rounded-full text-[18px] border border-black'> 1</h1>
                                    <div className=''>
                                        <h2 className='text-[18px]'>Advert Details</h2>
                                        <p>Provide as much information as possible, including an accurate description</p>
                                    </div>
                                </div>
                                <div className='flex gap-4 text-gray-500'>
                                    <h1 className='text-[#00000040] w-[30px] h-[30px] flex items-center justify-center rounded-[100%] text-[18px] border border-[#00000040]'>2</h1>
                                    <div className='text-[#00000073]'>
                                        <h2 className='text-[18px]'>Preview</h2>
                                        <h3 className='text-[16px]'>Everything look good?</h3>
                                    </div>
                                </div>
                                <div className='flex gap-4 text-gray-500'>
                                    <h1 className='text-[#00000040] w-[30px] h-[30px] flex items-center justify-center rounded-[100%] text-[18px] border border-[#00000040]'>3</h1>
                                    <div className='text-[#00000073]'>
                                        <h2 className='text-[18px]'>Payment</h2>
                                        <h3 className='text-[16px]'>from £29.99 per advert</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div onClick={checkLoginInfo} className='lg:w-8/12 p-4 bg-white rounded-xl' id='12'>
                            <form onSubmit={submitHandler} action="">
                                <div className='border-gray-300 flex justify-between border-2 p-2 rounded-xl'>
                                    <div className='w-full'>
                                        <h1 className='text-[16px]'>Category</h1>
                                        <select
                                            name="category"
                                            className='w-full text-[20px] font-bold focus:outline-none text-black'
                                            value={formData.category}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" className='text-[18px] text-black'>Select Category</option>
                                            <option value="Horse" className='text-[18px] text-black'>Horse</option>
                                            <option value="Horse Tack" className='text-[18px] text-black'>Horse Tack</option>
                                            <option value="Horsebox & Trailers" className='text-[18px] text-black'>Horsebox & Trailers</option>
                                            <option value="Property" className='text-[18px] text-black'>Property</option>
                                            <option value="Freelancer" className='text-[18px] text-black'>Freelancer</option>
                                            <option value="Job" className='text-[18px] text-black'>Job</option>
                                            <option value="Business Service" className='text-[18px] text-black'>Business Service</option>
                                        </select>

                                    </div>
                                </div>
                                {errors.category && <p className="text-red-600 text-sm">{errors.category}</p>}
                                <div className='border-gray-300 border-2 rounded-xl p-2 mt-2'>
                                    <h2 className='text-[16px]'>Advert Package</h2>
                                    <button className='bg-[#EBF2F0] w-full p-4 rounded-[10px] mt-2 text-start text-[#377f63] text-2xl'>£29.99 per advert</button>
                                    <div className='flex flex-col lg:flex-row'>
                                        <div className='lg:w-6/12 mt-2'>
                                            <ol className='list-disc'>
                                                <li className='pt-2'>Advert online for 6 weeks</li>
                                                <li className='pt-2'>Advert available on the Whickr Mobile App (iOS & Android)</li>
                                                <li className='pt-2'>3 Photos of your horse included</li>
                                                <li className='pt-2'>3 YouTube videos included</li>
                                                <li className='pt-2'>3 Videos (3 x 1 minute videos) included</li>
                                            </ol>
                                        </div>
                                        <div className='lg:w-6/12 mt-2'>
                                            <ol className='list-disc'>
                                                <li className='pt-2'>Option to include in the Horse & Hound Magazine (advertised for sale only)</li>
                                                <li className='pt-2'>Immediately notify matching buyers</li>
                                                <li className='pt-2'>Knowledgeable customer support</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className='border-gray-300 flex flex-col justify-between border-2 p-2 rounded-xl mt-2'>
                                    <h2 className='text-[16px] pt-2 pl-2'>Headline</h2>
                                    <input
                                        type="text"
                                        name='headline'
                                        className='w-full p-1 focus:outline-none'
                                        value={formData.headline}
                                        onChange={handleInputChange}
                                    />

                                </div>
                                {errors.headline && <p className="text-red-600 text-sm">{errors.headline}</p>}
                                <div className='flex flex-col lg:flex-row mt-2 gap-3'>
                                    <div className='w-6/12'>
                                        <div className='border-gray-300 p-2 border-2 rounded-xl '>
                                            <h1 className='text-[16px] pt-2 pl-1'>Sale Type</h1>
                                            <select
                                                name="sale_type"
                                                className='w-full text-[20px] font-bold focus:outline-none text-gray-400'
                                                value={formData.sale_type}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" className='text-[18px] text-black'>Select Sale Type</option>
                                                <option value="sale" className='text-[18px] text-black'>Sale</option>
                                                <option value="loan" className='text-[18px] text-black'>Loan</option>
                                            </select>

                                        </div>
                                    </div>
                                    {errors.sale_type && <p className="text-red-600 text-sm">{errors.sale_type}</p>}
                                    <div className='w-6/12'>
                                        <div className='border-gray-300 border-2 rounded-xl flex justify-between'>
                                            <div className='w-6/12'>
                                                <h1 className='text-[16px] pt-2 pl-2'>Price</h1>
                                                <div className='flex gap-2'>
                                                    <h2 className='font-bold text-[22px] font-bold pl-2 pb-2'>£</h2>
                                                    <input
                                                        type="number"
                                                        name='price'
                                                        className='h-8 w-28 focus:outline-none w-full'
                                                        value={formData.price}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.price && <p className="text-red-600 text-sm">{errors.price}</p>}

                                <div className='border-gray-300 border-2 mt-3 p-2 rounded-xl'>
                                    <h1 className=' text-[16px]'>Type</h1>
                                    <select
                                        name="type"
                                        className='w-full text-[20px] font-bold focus:outline-none text-gray-400'
                                        value={formData.type}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" className='text-[18px] text-black'>Select Type</option>
                                        <option value="Horse" className='text-[18px] text-black'>Horse</option>
                                        <option value="Pony" className='text-[18px] text-black'>Pony</option>
                                    </select>
                                </div>
                                {errors.type && <p className="text-red-600 text-sm">{errors.type}</p>}

                                <div className='flex mt-3 gap-4'>
                                    <div className='w-6/12'>
                                        <div className='border-gray-300 border-2 flex-col rounded-xl h-24'>
                                            <h1 className='text-[18px] mt-2 ml-2'>Age </h1>
                                            <input
                                                name='age'
                                                type="date"
                                                className='text-[24px] pl-2 focus:outline-none'
                                                value={formData.age}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    {errors.age && <p className="text-red-600 text-sm">{errors.age}</p>}
                                    <div className='w-6/12'>
                                        <div className='border-gray-300 border-2 rounded-xl'>
                                            <h1 className='text-[18px] mt-2 ml-2'>Height (hh)</h1>
                                            <div className='flex gap-2'>
                                                <input
                                                    type="number"
                                                    name='height'
                                                    className='w-full text-[24px] font-bold p-2 focus:outline-none'
                                                    value={formData.height}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.height && <p className="text-red-600 text-sm">{errors.height}</p>}

                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Color</h1>
                                    <input
                                        type="text"
                                        name='color'
                                        className='w-full text-[24px] font-bold pl-2 focus:outline-none'
                                        value={formData.color}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.color && <p className="text-red-600 text-sm">{errors.color}</p>}

                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Gender</h1>
                                    <select
                                        name="gender"
                                        className='w-full text-[20px] font-bold focus:outline-none text-gray-400'
                                        value={formData.gender}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" className='text-[18px] text-black'>Select Gender</option>
                                        <option value="male" className='text-[18px] text-black'>Male</option>
                                        <option value="female" className='text-[18px] text-black'>Female</option>
                                    </select>
                                </div>
                                {errors.gender && <p className="text-red-600 text-sm">{errors.gender}</p>}

                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Discipline</h1>
                                    <input
                                        type="text"
                                        name='discipline'
                                        className='w-full text-[24px] font-bold pl-2 focus:outline-none'
                                        value={formData.discipline}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.discipline && <p className="text-red-600 text-sm">{errors.discipline}</p>}

                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Breed</h1>
                                    <input
                                        type="text"
                                        name='breed'
                                        className='w-full text-[24px] font-bold pl-2 focus:outline-none'
                                        value={formData.breed}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.breed && <p className="text-red-600 text-sm">{errors.breed}</p>}
                                <div>
                                    {/* Your other component code */}
                                    <div className="border-gray-300 border-2 mt-3 p-2 rounded-xl">
                                        <h2 className="text-[18px]">Media</h2>
                                        <p className="text-gray-500">
                                            Additional media can be added for £5 per photo / video. Drag to reorder.
                                        </p>
                                        <div className="items-center">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                                ref={fileInputRef}
                                                className="hidden" // Hide the default file input
                                            />
                                            <button
                                                type="button"
                                                onClick={handleButtonClick}
                                                className="py-2 px-4 w-full bg-blue-500 text-white rounded mb-3"
                                            >
                                                Upload Image
                                            </button>
                                            {image && (
                                                <div className="relative">
                                                    <img
                                                        src={image}
                                                        alt="upload"
                                                        className="md:w-40 md:h-40 w-[80px] h-[80px] object-cover rounded"
                                                    />
                                                    <button
                                                        onClick={handleRemoveImage}
                                                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='mt-4'>
                                        {links.map((link, index) => (
                                            <div key={index} className='border border-gray-300 p-2 mb-2 rounded'
                                                style={{ display: remove ? 'none' : '' }}>
                                                <div className='font-bold'>YouTube Video</div>
                                                <div className='flex'>
                                                    <input
                                                        type='text'
                                                        value={link}
                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                        placeholder='Enter YouTube link'
                                                        className='w-full p-2 focus:outline-none border-gray-300 rounded'
                                                    />
                                                    <button onClick={() => removeLink(index)}><IoIosRemoveCircle className='w-[30px] h-[30px] text-red-600 hover:text-red-800' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button onClick={addLinkDiv} className='text-green-800 rounded-xl bg-gray-100 text-xl w-full h-12 hover:text-black hover:bg-gray-300'> +  Add Youtube Video</button>
                                </div>
                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Description</h1>
                                    <textarea
                                        name='description'
                                        className='w-full h-20 text-[24px] font-bold pl-2 focus:outline-none'
                                        value={formData.description}
                                        onChange={handleInputChange}
                                    ></textarea>
                                </div>
                                {errors.description && <p className="text-red-600 text-sm">{errors.description}</p>}

                                <div className='border-gray-300 border-2 flex-col mt-2 p-2 rounded-xl'>
                                    <h1 className='text-[18px] mt-2 ml-2'>Location</h1>
                                    <Autocomplete
                                        name='location'
                                        className='w-full text-[24px] pl-2 focus:outline-none'
                                        value={formData.location}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.location && <p className="text-red-600 text-sm">{errors.location}</p>}

                                <button
                                    type="submit"
                                    className='bg-[#377f63] px-10 block mx-auto text-white py-2 rounded-[10px] mt-2'
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Createadvart