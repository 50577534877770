import React, { useEffect, useState } from 'react';
import { FaAngleDown } from "react-icons/fa";
import FliterSection from './FliterSection';
import PostSection from './PostSection';
import { IoIosArrowForward } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaArrowUp } from "react-icons/fa";

const FetureSection = () => {
    const [showSections, setShowSections] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div className='w-full min-h-screen bg-slate-100'>
            <div className="container grid grid-cols-1 sm:grid-cols-1 md:flex lg:flex xl:flex">
                <div className='flex flex-col sm:order-last md:flex-row w-full justify-between gap-4'>
                    <div className='my-4 w-full md:w-[60%]'>
                        <PostSection />
                    </div>
                    <div className='w-full order-1 md:w-[32%] my-4'>
                        <button
                            className='md:hidden text-green-800 bg-gray-100 py-2 px-4 border-[1px] border-green-800 rounded-md mb-4'
                            onClick={() => setShowSections(!showSections)}
                        >
                            <div className='flex items-center gap-2'>
                                <span className=' duration-300'>{showSections ? <span className='text-xl'><MdKeyboardArrowDown /></span> : <span> <IoIosArrowForward /></span>}</span>
                                <span> {showSections ? "Change Sort & Fliter" : "Change Sort & Fliter"}</span>
                            </div>
                        </button>
                        {(showSections || window.innerWidth >= 768) && (
                            <div className='md:block'>
                                <div className='bg-white flex justify-between shadow-md rounded-md px-4 py-3'>
                                    <CustomDropdown />
                                </div>
                                <div className='mt-2'>
                                    <FliterSection />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="fixed bottom-4 right-4">
                    {isVisible && (
                        <button
                            onClick={scrollToTop}
                            className="w-[50px] text-white h-[50px] bg-green-700 hover:bg-green-800 flex justify-center items-center fixed bottom-10 rounded-full right-5 cursor-pointer">
                            <FaArrowUp className='h-[30px] w-[30px]' />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FetureSection;

const CustomDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Newest Listed");

    const options = [
        "Date",
        "Newest Listed",
        "Oldest Listed",
        "Price",
        "Highest Price",
        "Lowest Price",
    ];

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left w-full">
            <p className='font-semibold text-xl'>Sort</p>
            <div className="border-[1px] border-gray-300 hover:border-green-800 rounded-md p-2 bg-white cursor-pointer" onClick={toggleDropdown}>
                <div className='flex justify-between items-center'>
                    <span>{selectedOption}</span>
                    <span><FaAngleDown /></span>
                </div>
            </div>
            {isOpen && (
                <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                    <ul className="py-1">
                        {options.map((option) => (
                            <li
                                key={option}
                                className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 ${option === "Date" || option === "Price" ? "text-sm text-gray-500" : "text-base text-gray-900"}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
