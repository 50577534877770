import React, { useEffect, useState } from 'react';
import { FiArrowRight } from "react-icons/fi";
import horseImg1 from '../../assets/horse.jpg';
import horseImg2 from '../../assets/horse1.jpg';
import horseImg3 from '../../assets/horese2.jpg';
import horseImg4 from '../../assets/horse3.jpg';
import horseImg5 from '../../assets/horse4.jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';

const cardData = [
  {
    id: 1,
    slug: 'pony-club-pony',
    imgSrc: horseImg1,
    price: 'FREE',
    saleType: 'Loan',
    title: 'Pony club pony',
    sellerInfo: 'Hollie Ryder in Saffron Walden',
    details: ['13yrs', '12.3hh', 'Mare']
  },

  {
    id: 2,
    slug: 'show-jumping-horse',
    imgSrc: horseImg2,
    price: '£500',
    saleType: 'Sale',
    title: 'Show Jumping Horse',
    sellerInfo: 'John Doe in Newmarket',
    details: ['8yrs', '16hh', 'Gelding']
  },
  {
    id: 3,
    slug: 'dressage-horse',
    imgSrc: horseImg3,
    price: '£1500',
    saleType: 'Sale',
    title: 'Dressage Horse',
    sellerInfo: 'Jane Smith in Oxford',
    details: ['6yrs', '15.2hh', 'Mare']
  },
  {
    id: 1,
    slug: '/pony-club-pony-2',
    imgSrc: horseImg4,
    price: 'FREE',
    saleType: 'Loan',
    title: 'Pony club pony',
    sellerInfo: 'Hollie Ryder in Saffron Walden',
    details: ['13yrs', '12.3hh', 'Mare']
  },

  {
    id: 2,
    slug: '/John-doe-in-newmarket',
    imgSrc: horseImg5,
    price: '£500',
    saleType: 'Sale',
    title: 'Show Jumping Horse',
    sellerInfo: 'John Doe in Newmarket',
    details: ['8yrs', '16hh', 'Gelding']
  },
];

const PostSection = () => {

  const [posts, setPosts] = useState()
  const advAPI = process.env.REACT_APP_SHOW_ADV_API_URL
  useEffect(
    () => {
      axios.post(advAPI)
        .then(
          (success) => {
            setPosts(success.data.data)
          }
        ).catch(
          (err) => {
            console.log(err)
          }
        )
    }, []
  )

  if (!posts) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    )
  }

  return (
    <div className="grid gap-4 sm:grid-cols-1">
      {posts?.map((card, index) => (
        <Link key={index} to={card.id} className="block relative overflow-hidden rounded-lg shadow-md no-underline">
          <div className="p-0">
            <div className="relative w-full h-80 bg-cover bg-center">
              <img
                src={`https://crm.commoditylevels.com/${card.image}`}
                alt="Horse post thumbnail"
                className="object-cover w-full h-full"
              />
              <div className="absolute -bottom-8 shadow-xl hover:scale-125 duration-300 right-10 rounded-full p-[14px] bg-white z-10">
                <img
                  src="https://www.whickr.com/img/heartEmptyIcon.svg"
                  alt="post-heart-img"
                  className="w-8 h-8"
                />
              </div>
            </div>
            <div className="p-4 bg-white">
              <div className="flex justify-between items-start mb-2">
                <div className="flex flex-col">
                  <div className="flex items-center uppercase gap-3">
                    <h4 className="text-xl pt-2 font-bold text-green-800">£{card.price}</h4>
                    <span className="bg-purple-100 text-blue-800 px-4 font-semibold rounded-full">{card.sale_type}</span>
                  </div>
                  <p className="block text-2xl font-bold text-gray-900 mt-1">{card.title}</p>
                  <p className="text-[16px] text-gray-600">{card.discipline}</p>
                </div>
                <div className="flex items-center relative space-x-4">
                  <Link to={`/showPostSection/${card.id}`}>
                    <div className="flex items-center text-gray-600">
                      <span className="text-xl md:block lg:block xl:block hidden text-green-800 absolute top-[150px] right-10">View</span>
                      <span className="text-xl text-green-800 absolute md:top-[157px] lg:top-[157px] xl:top-[157px] top-[150px] right-3"><FiArrowRight /></span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex md:space-x-4 lg:space-x-4 items-center xl:space-x-4 space-x-2 text-xl text-green-800 mt-2">
                <li className="md:block lg:block xl:block flex flex-col">{card.age}</li>
                <li className="md:block lg:block xl:block flex flex-col">|</li>
                <li className="md:block lg:block xl:block flex flex-col">{card.height}</li>
                <li className="md:block lg:block xl:block flex flex-col">|</li>
                <li className="md:block lg:block xl:block flex flex-col">{card.gender}</li>
              </div>
            </div>
          </div>
        </Link>
      ))
      }
    </div >

  );
};

export default PostSection;
