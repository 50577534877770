import React from 'react';
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { FaToggleOff } from "react-icons/fa";

function Logout() {

  const navigate = useNavigate()

  const logoutHandler = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPassword');
    navigate('/')
  }

  return (
    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="shadow-2xl bg-white rounded-xl p-8">
        <h1 className="text-center text-2xl font-bold mb-8">
          <FaRegQuestionCircle className="inline-block text-yellow-400 text-3xl mr-2" />
          Are you sure?
        </h1>
        <p className="text-center mb-4">
          You will need to log back in to gain access to all features.
        </p>
        <div className="flex justify-center space-x-4">
          <Link to={'/'}>
            <button className="bg-gray-100 text-green-600 px-6 py-3 rounded-xl font-bold hover:bg-gray-200 transition duration-300">Cancel</button>
          </Link>
          <a href="#">
            <button onClick={logoutHandler} className="bg-green-800 text-white px-6 py-3 rounded-xl font-bold hover:bg-green-700 transition duration-300">Yes, Log me out</button>

          </a>
        </div>
      </div>
    </div>
  );
}

export default Logout;
