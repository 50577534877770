import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Blog() {
  const [blogs, setBlogs] = useState(null);
  const blogAPI = process.env.REACT_APP_BLOG_API_URL
  useEffect(() => {
    axios.post(blogAPI)
      .then((response) => {
        setBlogs(response.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  if (!blogs) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    )
  }

  return (
    <>
      <div>
        {
          blogs.map(
            (item, index) => {
              return <div key={index} className='max-w-[1200px] mx-auto bg-white md:px-16 xl:px-2 py-10 rounded-lg'>
                <div className='lg:w-10/12 mx-auto'>
                  <h1 className='text-[48px] text-gray-700 text-center'>Equestrian Blog</h1>
                  <p className='text-center text-gray-500 mt-2 text-lg'>
                    Talking all things horses; buying horses, advertising horses for sale, breed <br /> profiles, and training exercises for horse and rider.
                  </p>

                  <div className='flex flex-col lg:flex-row mt-10 px-2'>
                    <img className='w-[270px] h-[185px] lg:w-96 rounded-xl mx-auto lg:mx-0'
                      src={`https://crm.commoditylevels.com/${item.thumbnail}`}
                      //  src="https://www.whickr.com/images/blog/new-features-to-maximise-the-visibility-of-your-horse-for-sale.jpg" 
                      alt="Blog post" />

                    <div className='lg:ml-10 mt-8 lg:mt-0 lg:flex-1'>
                      <p className='text-[30px] text-gray-700 leading-9 font-semibold'>{item.title}</p>
                      <p className='text-[18px] text-gray-500 mt-2'>{item.author}</p>
                      <p className='text-gray-600 mt-2'>{item.summary}</p>
                    </div>
                  </div>

                  <div className='flex items-center cursor-pointer hover:text-blue-600 mt-6'>
                    <Link to={item.slug}> <h1 className='text-[16px] font-medium px-2'>Read more</h1> </Link>
                    <Link to={item.slug}>    <FaArrowRight className='ml-2' /> </Link>
                  </div>
                  <hr className='border-gray-300 w-[40%] mx-auto mt-8' />
                </div>
              </div>
            }
          )
        }
      </div>
    </>
  );
}

export default Blog;
