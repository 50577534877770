import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ShowPostSection = () => {
    const [details, setDetails] = useState(null);
    const { id } = useParams();
    const advDetailsAPI = process.env.REACT_APP_CREATE_ADV_DETAIL_API_URL;

    useEffect(() => {
        axios.post(advDetailsAPI, { id })
            .then((success) => {
                console.log(success, 'success');
                setDetails(success.data.data);
            })
            .catch((err) => {
                console.log(err, 'error');
            });
    }, [id]);

    return (
        <div className='container mx-auto p-4'>
            {details ? (
                <div className="relative">
                    {/* Responsive image */}
                    <img 
                        src={`https://crm.commoditylevels.com/${details.image}`} 
                        className='w-full md:w-[800px] lg:w-[1200px] md:h-[400px]' 
                        alt={details.title} 
                    />

                    <div className="p-4 md:p-5 shadow-xl rounded-lg md:rounded-b-[10px]">
                        <div className='flex flex-col md:flex-row items-center gap-3'>
                            <h4 className="text-lg md:text-xl text-green-800 font-semibold">£{details.price}</h4>
                            <span className="mt-2 md:mt-0 font-medium bg-gray-200 text-blue-800 px-3 py-1 rounded-full text-sm">{details.sale_type}</span>
                        </div>
                        <h1 className="text-xl md:text-2xl font-bold mt-2">{details.title}</h1>
                        <p className="text-gray-600">{details.location}</p>
                        <div className="mt-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                            <button className="bg-gray-100 border-[1px] hover:border-green-800 text-green-800 text-lg md:text-xl px-4 py-2 rounded-lg">Contact Seller</button>
                            <button className="bg-gray-100 border-[1px] hover:border-green-800 text-green-800 text-lg md:text-xl px-4 py-2 rounded-lg">Share</button>
                        </div>

                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            <dl>
                                <dt className="font-semibold text-gray-400">Height</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.height}</dd>
                            </dl>
                            <dl>
                                <dt className="font-semibold text-gray-400">Age</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.age}</dd>
                            </dl>
                            <dl>
                                <dt className="font-semibold text-gray-400">Colour</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.colour}</dd>
                            </dl>
                            <dl>
                                <dt className="font-semibold text-gray-400">Breed</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.breed}</dd>
                            </dl>
                            <dl>
                                <dt className="font-semibold text-gray-400">Gender</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.gender}</dd>
                            </dl>
                            <dl>
                                <dt className="font-semibold text-gray-400">Type</dt>
                                <dd className='text-lg md:text-2xl font-semibold'>{details.type}</dd>
                            </dl>
                        </div>

                        <div className="mt-2">
                            <h3 className="text-lg font-semibold">Description</h3>
                            <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: details.description }}></p>
                        </div>
                        <button className='text-white border mx-auto block py-2 px-4 rounded-xl hover:bg-green-600 bg-green-400 text-lg md:text-xl text-center mt-5'>Report Advert</button>
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center min-h-screen">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                </div>
            )}
        </div>
    );
};

export default ShowPostSection;
