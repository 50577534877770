import React, { useState } from 'react';
import Autocomplete from "react-google-autocomplete";
import axios from 'axios'; // Import Axios

const FilterSection = () => {
    const [location, setLocation] = useState('');
    const [miles, setMiles] = useState('');
    const [saleType, setSaleType] = useState('');
    const [ageMin, setAgeMin] = useState('');
    const [ageMax, setAgeMax] = useState('');
    const [heightMin, setHeightMin] = useState('');
    const [heightMax, setHeightMax] = useState('');
    const [horseType, setHorseType] = useState('');
    const [gender, setGender] = useState('');
    const [colour, setColour] = useState('');
    const [discipline, setDiscipline] = useState('');
    const [budgetMin, setBudgetMin] = useState('');
    const [budgetMax, setBudgetMax] = useState('');
    const [excludePOA, setExcludePOA] = useState(false);

    const horseTypeOptions = ["select", "Horse", "Pony"];
    const genderOptions = ["select", "Mare", "Gelding", "Stallion"];
    const horseColors = [
        "select",
        "Appaloosa",
        "Bay",
        "Bay Roan",
        "Black",
        "Blue Roan",
        "Brown",
        "Buckskin",
        "Chestnut",
        "Cremello",
        "Dapple Grey",
        "Dark Bay",
        "Dark Brown",
        "Dun",
        "Flea-bitten Grey",
        "Grey",
        "Iron Grey",
        "Liver Chestnut",
        "Palomino",
        "Piebald",
        "Roan",
        "Rose Grey",
        "Skewbald",
        "Spotted",
        "Strawberry Roan",
        "Tri-Coloured"
    ];

    const desNames = [
        "select",
        "All-Rounder",
        "Broodmare",
        "Companion",
        "Dressage",
        "Driving",
        "Endurance",
        "Eventing",
        "Ex Racehorse",
        "Happy Hacker",
        "Hunting",
        "Mother-daughter share",
        "Polo",
        "Pony Club",
        "Riding Club",
        "Show Jumping",
        "Showing",
        "Youngstock",
        "All Rounder",
        "Broodmare",
        "Companion",
        "Dressage",
        "Driving",
        "Endurance",
        "Eventing",
        "Happy Hacker",
        "Hunting",
        "Mother-daughter share",
        "Polo",
        "Pony Club",
        "Racing",
        "Riding Club",
        "Show Jumping",
        "Showing",
        "Youngstock"
    ];

    const handleClearAll = () => {
        setLocation('');
        setMiles('');
        setSaleType('');
        setAgeMin('');
        setAgeMax('');
        setHeightMin('');
        setHeightMax('');
        setHorseType('');
        setGender('');
        setColour('');
        setBudgetMin('');
        setBudgetMax('');
        setExcludePOA(false);
    };

    const advAPI = process.env.REACT_APP_SHOW_ADV_API_URL

    const handleApplyFilter = () => {
        const filters = {
            location,
            miles,
            saleType,
            ageMin,
            ageMax,
            heightMin,
            heightMax,
            horseType,
            gender,
            discipline,
            colour,
            budgetMin,
            budgetMax,
            excludePOA
        };

        axios.post(advAPI, filters)
            .then(response => {
                console.log('Response:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="p-7 bg-white rounded-md shadow-xl">
            <form action="" >
                <div className="flex justify-between">
                    <h3 className="font-semibold text-lg mb-2">Filters</h3>
                </div>

                <p className="filterTitle">Location</p>
                <div>
                    <div className="relative">
                        <Autocomplete className='focus:outline-none border p-2 w-full rounded'
                            apiKey={'AIzaSyCqS-_TBx6GtHf-PSr7Ia2dNDhNVWkPPH4'}
                            onPlaceSelected={(place) => {
                                setLocation(place.formatted_address);
                            }}
                        />
                    </div>
                </div>

                <div className="my-4">
                    <label htmlFor="miles" className="block">Distance</label>
                    <div className="relative">
                        <select
                            id="miles"
                            value={miles}
                            onChange={(e) => setMiles(e.target.value)}
                            className="w-full border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded py-2 px-2"
                        >
                            <option value="">Select</option>
                            <option value="+ 50 Miles"> + 50 Miles</option>
                            <option value="+ 100 Miles">+ 100 Miles</option>
                            <option value="+ 200 Miles">+ 200 Miles</option>
                            <option value="+ 300 Miles">+ 300 Miles</option>
                            <option value="+ 500 Miles">+ 500 Miles</option>
                        </select>
                    </div>
                </div>

                <p className="filterTitle">Sale Type</p>
                <div className="my-4">
                    <div className="flex space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="Sale"
                                checked={saleType === 'Sale'}
                                onChange={() => setSaleType('Sale')}
                                className="mr-2"
                            />
                            Sale
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="Loan"
                                checked={saleType === 'Loan'}
                                onChange={() => setSaleType('Loan')}
                                className="mr-2"
                            />
                            Loan
                        </label>
                    </div>
                </div>

                <p className="filterTitle">Age <span className="text-gray-500">(years)</span></p>
                <div className="flex space-x-4">
                    <input
                        type="number"
                        placeholder="Min"
                        value={ageMin}
                        onChange={(e) => setAgeMin(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                    <input
                        type="number"
                        placeholder="Max"
                        value={ageMax}
                        onChange={(e) => setAgeMax(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                </div>

                <p className="filterTitle">Height</p>
                <div className="flex space-x-4">
                    <input
                        type="number"
                        placeholder="Min"
                        value={heightMin}
                        onChange={(e) => setHeightMin(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                    <input
                        type="number"
                        placeholder="Max"
                        value={heightMax}
                        onChange={(e) => setHeightMax(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                </div>

                <p className="filterTitle mt-2">Horse Type</p>
                <div className="mb-2">
                    <div className="relative">
                        <select
                            value={horseType}
                            onChange={(e) => setHorseType(e.target.value)}
                            className="w-full border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                        >
                            {horseTypeOptions.map((option, opt) => (
                                <option key={opt} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <p className="filterTitle">Gender</p>
                <div className="my-4">
                    <div className="relative">
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="w-full border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                        >
                            {genderOptions.map((option, op) => (
                                <option key={op} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <p className="filterTitle">Colour</p>
                <div className="my-4">
                    <div className="relative">
                        <select
                            value={colour}
                            onChange={(e) => setColour(e.target.value)}
                            className="w-full border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                        >
                            {horseColors.map((option, list) => (
                                <option key={list} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <p className="filterTitle">Budget <span className="text-gray-500">(£)</span></p>
                <div className="flex space-x-4">
                    <input
                        type="number"
                        placeholder="Min"
                        value={budgetMin}
                        onChange={(e) => setBudgetMin(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                    <input
                        type="number"
                        placeholder="Max"
                        value={budgetMax}
                        onChange={(e) => setBudgetMax(e.target.value)}
                        className="w-1/2 border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                    />
                </div>

                <p className="filterTitle">Disciplines</p>
                <div className="my-2">
                    <div className="relative">
                        <select
                            value={discipline} 
                            onChange={(e) => setDiscipline(e.target.value)} 
                            className="w-full border-[1px] border-gray-300 cursor-pointer hover:border-green-800 rounded p-2"
                        >
                            {desNames.map((option, li) => (
                                <option key={li} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-col space-y-3  mt-6">
                    <button
                        type="button"
                        onClick={handleApplyFilter}
                        className="px-4 py-2 bg-green-800 text-white rounded hover:bg-green-700"
                    >
                        Apply Filter
                    </button>
                    <button
                        type="button"
                        onClick={handleClearAll}
                        className="px-4 py-2 bg-gray-50  text-black rounded border-gray-300 border-[1px] hover:border-green-800"
                    >
                        Clear All
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FilterSection;
