import React, { useEffect, useState } from 'react';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';
import FetureSection from './FetureSection';
import { Link, useNavigate } from 'react-router-dom';

const Marketplace = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [Name, setName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "createwantedadvert", link: "Create Wanted Advert" },
  ];

  return (
    <>
      <div className='w-full'>
        <div className='py-3 bg-slate-100'>
          <div className='container py-2 flex flex-wrap justify-between items-center'>
            <ul className='flex flex-wrap gap-4 md:gap-8'>
              {headerMenu.map((item, index) => (
                <Link key={index} to={item.path} className='no-underline'>
                  <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                    <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                    <span className='text-black text-sm md:text-xl'>{item.link}</span>
                  </li>
                </Link>
              ))}
            </ul>
            <div className="relative inline-block text-left">
              {showLoginPopup
                ? <div
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                  className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
                >
                  <span className='text-xl md:text-3xl'><CiUser /></span>
                  <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
                  <p className='text-xl my-auto'><FaAngleDown /></p>
                  {isOpen && (
                    <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                        <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                        <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                        <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                        <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                        <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                      </div>
                    </div>
                  )}
                </div>
                : <div
                  className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                  <Link to={'/login'} className='text-green-600 font-bold'>
                    <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                      Login
                    </div>
                  </Link>
                </div>
              }
            </div>
          </div>
          {/* <div className='container mx-auto'>
            <MarketplaceItem />
          </div> */}
        </div>
      </div>
      <FetureSection />
    </>
  );
};

const MarketplaceItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  const items = [
    { itemName: "Horses", path: "Horses" },
    { itemName: "Horse Track", path: "Horse-Track" },
    { itemName: "HorseBoxs & Trailers", path: "horseboxes-trailers" },
    { itemName: "Properties", path: "properties" },
    { itemName: "Freelancers", path: "freelancers" },
  ];

  const Categories = [
    { itemName: "Jobs", path: "/jobs" },
    { itemName: "Business Services", path: "/business-services" },
    { itemName: "Stallions at Stud", path: "/stallions-stud" },
    { itemName: "Livery", path: "/livery" },
    { itemName: "Dogs", path: "/dogs" },
  ];

  const mobCategories = [
    { itemName: "Horses" },
    { itemName: "Horse Track" },
    { itemName: "HorseBoxs & Trailers" },
    { itemName: "Properties" },
    { itemName: "Freelancers" },
    { itemName: "Jobs" },
    { itemName: "Business Services" },
    { itemName: "Stallions at Stud" },
    { itemName: "Livery" },
    { itemName: "Dogs" },
  ];
  return (
    <div className='flex flex-wrap gap-[10px] items-center'>
      {/* Desktop View Categories */}
      <div className='hidden md:flex gap-[10px] items-center'>
        {items.map((item, index) => (
          <Link key={index} to={item.path} className='no-underline'> <p

            className='duration-300 cursor-pointer border-2 hover:border-green-800 bg-white py-1 px-3 rounded-full text-[18px] text-green-700'
          >
            {item.itemName}
          </p></Link>
        ))}
        <div
          className='relative inline-block text-left'
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div
            className='cursor-pointer border-2 hover:border-green-800 bg-white px-3 py-1 rounded-full text-[18px] text-green-700 flex items-center gap-1'
          >
            <p className='font-semibold text-[16px]'>Other Categories</p>
            <p className='text-xl mt-1'><FaAngleDown /></p>
          </div>
          {isOpen && (
            <div className='absolute z-30 right-0 mt-2 w-56 origin-top-right left-2 top-11 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5'>
              <div className='py-1 z-99999'>
                {Categories?.map((category, index) => (
                  <Link key={index} to={category.path} className='no-underline'>   <li

                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                  >
                    {category.itemName}
                  </li>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile View Categories */}
      <div className='md:hidden w-full z-50'>
        <button
          className='bg-green-700 text-white py-2 px-4 rounded-full mb-4 flex items-center gap-2'
          onClick={() => setShowCategories(!showCategories)}
        >
          <span className='text-xl'>
            {showCategories ? <MdKeyboardArrowDown /> : <IoIosArrowForward />}
          </span>
          <span>{showCategories ? "Categories" : "Categories"}</span>
        </button>
        {showCategories && (
          <div className='w-full bg-white shadow-xl'>
            {mobCategories.map((cat, index) => (
              <ul key={index} className='py-2 border-b border-gray-200'>
                <li className='px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                  {cat.itemName}
                </li>
              </ul>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Marketplace;
