import React, { useState } from 'react';
import { ImWhatsapp } from "react-icons/im";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';

function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    Description: '',
    mobile: ''
  });

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    const { name, email, subject, Description, mobile } = formData;

    if (!name) errors.name = 'Name is required.';
    if (!email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid.';
    }
    if (!subject) errors.subject = 'Subject is required.';
    if (!Description) errors.Description = 'Message is required.';
    if (!mobile) errors.mobile = 'Mobile is required'

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const showpostapi = process.env.REACT_APP_SHOWPOST_API_URL

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Submitting form with data:', formData);

      axios.post(showpostapi, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log('Response:', response);
          if (response.data.status) {
            setFormData({
              name: '',
              email: '',
              subject: '',
              Description: '',
              mobile: ''
            });
          }
        })
        .catch((error) => {
          console.error('Error details:', error.response || error.message || error);
        });
    }
  };

  return (
    <>
      <div className='bg-gray-100 pb-3'>
        <div className='max-w-[1100px] gap-3 mx-auto flex flex-col md:flex-row md:px-16'>
          <div className='w-full md:w-7/12 mx-auto md:ml-0 md:mr-10 bg-white shadow-sm p-2 mt-3'>
            <form onSubmit={handleSubmit}>
              <h1 className='font-bold mt-2 md:text-left'>Send us a message</h1>
              <p className='md:text-left'>Fill out the form to send us a message and we'll get back to you as soon as we can. That's usually within 24 hours.</p>

              <div className='flex flex-col md:flex-row mt-2'>
                <div className='flex flex-col w-full md:w-1/2'>
                  <label htmlFor="name">Your Name</label>
                  <input
                    className='border-green-800 mt-2 rounded-xl p-1 border-2 w-full'
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && <span className='text-red-600'>{formErrors.name}</span>}
                </div>
                <div className='flex flex-col w-full md:w-1/2 md:mt-0 md:ml-4'>
                  <label htmlFor="email">Your Email</label>
                  <input
                    className='border-green-800 mt-2 rounded-xl p-1 border-2 w-full'
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && <span className='text-red-600'>{formErrors.email}</span>}
                </div>
              </div>
              <div className='flex flex-col md:flex-row mt-2 gap-4'>
                <div className='flex flex-col w-full md:w-1/2'>
                  <label htmlFor="mobile">Mobile</label>
                  <input
                    className='border-green-800 mt-2 rounded-xl p-1 border-2 w-full'
                    type="number"
                    name="mobile"
                    id="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                  {formErrors.mobile && <span className='text-red-600'>{formErrors.mobile}</span>}
                </div>

                <div className='flex flex-col w-full md:w-1/2'>
                  <label htmlFor="subject">Subject</label>
                  <input
                    className='border-green-800 mt-2 rounded-xl p-1 border-2 w-full'
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  {formErrors.subject && <span className='text-red-600'>{formErrors.subject}</span>}
                </div>
              </div>
              <div className='mt-2'>
                <label htmlFor="Description">Message</label>
                <textarea
                  className='border-green-800 mt-2 rounded-xl p-1 border-2 w-full h-32'
                  name="Description"
                  id="Description"
                  value={formData.Description}
                  onChange={handleChange}
                />
                {formErrors.Description && <span className='text-red-600'>{formErrors.Description}</span>}
              </div>

              <div className='mt-2'>
                <button
                  type='submit'
                  className='hover:bg-green-800 bg-green-700 w-full md:w-48 p-3 text-white font-bold rounded-xl mx-auto md:mx-0 block'
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>

          {/* Social Links */}
          <div className='w-full md:w-5/12 mx-auto md:ml-10 bg-white shadow-sm mt-3 p-3'>
            <h1 className='font-bold md:text-left'>We're social</h1>
            <p className='md:text-left'>You can also contact us at the following places:</p>

            <div className='w-full h-36 bg-white border-gray-200 border-2 rounded-xl flex items-center'>
              <ImWhatsapp className='w-12 h-16 ml-8 text-green-800' />
              <div className='ml-4'>
                <h1 className='text-[18px]'>WhatsApp</h1>
                <Link to={'https://web.whatsapp.com/'} className='text-green-700 font-bold no-underline text-[16px]'>Click To Message</Link>
              </div>
            </div>

            <div className='w-full h-36 bg-white border-gray-200 border-2 rounded-xl mt-2 flex items-center'>
              <FaFacebook className='w-12 h-16 ml-8 text-green-800' />
              <div className='ml-4'>
                <h1 className='text-[18px]'>Facebook</h1>
                <Link to={'https://www.facebook.com/'} className='text-green-700 font-bold no-underline text-[16px]'>Click To Message</Link>
              </div>
            </div>

            <div className='w-full h-36 bg-white border-gray-200 border-2 rounded-xl flex items-center mt-2'>
              <FaInstagramSquare className='w-12 h-16 ml-8 text-green-800' />
              <div className='ml-4'>
                <h1 className='text-[18px]'>Instagram</h1>
                <Link to={'https://www.instagram.com/'} className='text-green-700 font-bold no-underline text-[16px]'>Click To Message</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Mailing List Signup */}
        <div className='max-w-[1100px] bg-gray-100 ps-6 md:ps-16 pr-6 md:mx-auto mt-8'>
          <div className='bg-white shadow-sm w-full h-full mt-2 pt-4 pb-8'>
            <p className='text-center text-[16px] md:text-3xl'>Sign up to our mailing list!</p>
            <div className='text-center mt-2'>
              <input className='border-gray-400 border p-2 rounded-xl' type="text" placeholder='First Name' name="" id="" />
              <input className='border-gray-400 border p-2 rounded-xl ml-4' type="text" placeholder='Email' name="" id="" />
              <a href="#">
                <button className='hover:bg-green-800 bg-green-700 w-full md:w-36 ml-4 p-2 text-white font-bold rounded-xl'>Sign Up!</button>
              </a>
            </div>
            <div className='text-center w-[600px] mx-auto mt-6'>
              <p className='text-gray-400 text-[12px]'>By clicking submit above, you consent to allow Whickr to store and process the
                personal information submitted above to provide you the content requested.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactus;
