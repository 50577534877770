import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'primeicons/primeicons.css';
import { MdDelete } from "react-icons/md";
import Autocomplete from "react-google-autocomplete";
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import axios from 'axios';

function Editprofile() {
  const [location, setLocation] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isOn, setIsOn] = useState(false);
  const [errors, setErrors] = useState({});
  const [Name, setName] = useState('')
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    bio: '',
    location: ''
  });

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setFormData({
        ...formData,
        image: e.target.files[0]
      });
    }
  };

  const removeImage = () => {
    setFileName('');
    setFormData({
      ...formData,
      image: null // Reset the image in formData
    });
  };

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);


  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login');
    }
  };


  const validateForm = () => {
    const newErrors = {};

    if (!formData.name?.trim()) {
      newErrors.name = 'First name is required';
    }

    if (!formData.mobile?.trim()) {
      newErrors.mobile = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = 'Phone number must be 10 digits';
    }
    if (!location?.trim()) {
      newErrors.location = 'Location is required';
    }
    if (!formData.bio?.trim()) {
      newErrors.bio = 'Bio is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const apiFormData = new FormData();
    apiFormData.append('name', formData.name);
    apiFormData.append('mobile', formData.mobile);
    apiFormData.append('bio', formData.bio);
    apiFormData.append('location', location);
    apiFormData.append('id', userId);

    axios.post(`https://crm.commoditylevels.com/api/updateprofile`, apiFormData)
      .then(response => {
        console.log(response, 'success');
        if (response.data.status == 'true') {
          navigate('/')
        }
      })
      .catch(err => {
        console.log(err, 'error');
      });
  };
  const loginUserDataAPI = process.env.REACT_APP_LOGIN_API_URL;

  useEffect(() => {
    axios.post(loginUserDataAPI)
      .then(response => {
        console.log(response, 'userData');
      })
      .catch(err => {
        console.log(err, 'error');
      });
  }, [loginUserDataAPI]);

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    setUserId(user_id);
  }, []);

  // Fetch user data by ID
  const userbyIdAPI = process.env.REACT_APP_USERBYID_API_URL;

  useEffect(() => {
    if (userId) {
      axios.get(`${userbyIdAPI}/${userId}`)
        .then(response => {
          const data = response.data.data;
          console.log('Fetched data:', data);
          setUserData(data);
          setFormData({
            name: data.name || '',
            mobile: data.mobile || '',
            bio: data.bio || '',
            location: data.location || '',
            image: ''
          });
          setLocation(data.location || '');
          setIsOn(false);
        })
        .catch(err => {
          console.log('API error:', err);
        });
    }
  }, [userId, userbyIdAPI]);


  return (
    <>
      <div className='bg-gray-100'>
      <div className='container py-2 flex flex-wrap justify-between items-center'>
        <ul className='flex flex-wrap gap-4 md:gap-8'>
          {headerMenu.map((item, index) => (
            <Link key={index} to={item.path} className='no-underline'>
              <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                <span className='text-black text-sm md:text-xl'>{item.link}</span>
              </li>
            </Link>
          ))}
        </ul>
        <div className="relative inline-block text-left">
          {showLoginPopup
            ? <div
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
            >
              <span className='text-xl md:text-3xl'><CiUser /></span>
              <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
              <p className='text-xl my-auto'><FaAngleDown /></p>
              {isOpen && (
                <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                    <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                    <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                    <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                    <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                    <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                  </div>
                </div>
              )}
            </div>
            : <div
              className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
              <Link to={'/login'} className='text-green-600 font-bold'>
                <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                  Login
                </div>
              </Link>
            </div>
          }
        </div>
      </div>

        <div onClick={checkLoginInfo} className='max-w-screen-lg mx-auto py-6 px-4'>
          <div className='flex flex-col lg:flex-row gap-8'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl p-4'>
              <h1 className='text-lg text-[#000000D9]'>My Account</h1>
              <ol className='list-none pl-0'>
                <Link to={"/editprofile"} className='text-[#377F63] no-underline'>
                  <li className='text-green-800 flex gap-2 mt-2'>
                    <span>Edit Profile</span>
                  </li>
                </Link>
                <Link to={"/changeemail"} className='text-[#377F63] no-underline'>
                  <li className='mt-1'>Change Email</li>
                </Link>
                <Link to={"/changepassword"} className='text-[#377F63] no-underline'>
                  <li className='mt-1'>Change Password</li>
                </Link>
              </ol>

              <div className='mt-2'>
                <h1 className='text-lg text-[#000000D9]'>My Adverts</h1>
                <ol className='list-none pl-0'>
                  <Link to={"/all"} className='text-[#377F63] no-underline'>
                    <li className='text-green-800 flex gap-2 mt-2 items-center'>
                      <span>All</span>
                      <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                    </li>
                  </Link>
                  <Link to={"/live"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Live</li>
                  </Link>
                  <Link to={"/expried"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Expired</li>
                  </Link>
                  <Link to={"/sold"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Sold</li>
                  </Link>
                </ol>
              </div>
              <div>
                <h1 className='text-lg text-[#000000D9]'>My Wanted Adverts</h1>
                <ol className='list-none pl-0'>
                  <Link to={'/live'} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Live</li>
                  </Link>
                </ol>
              </div>
            </div>

            <div className='w-full lg:w-8/12 bg-white rounded-lg shadow-2xl pb-8'>
              <form action="" onSubmit={submitHandler}>
                <div className='ml-4 mt-6'>
                  <h1 className='text-lg text-[#000000D9] m-0 p-0'>Tell us about yourself</h1>
                  <p className='text-sm text-gray-600 m-0 p-0'>This information will appear on your seller profile.</p>
                </div>
                <div className='w-full px-4 mt-2'>
                  <input
                    type="file"
                    name='image'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <div
                    className='bg-gray-50 border-gray-300 border-2 mt-6 rounded-xl h-36 flex flex-col items-center justify-center cursor-pointer'
                    onClick={handleClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className='w-12 h-12 text-green-500' viewBox="0 0 24 24" fill="currentColor">
                      <path d="M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z" />
                    </svg>
                    <p className='mt-2 text-lg text-center text-gray-600'>Click or drag file to upload profile picture</p>
                  </div>
                  {fileName && (
                    <p className='mt-2 text-center flex justify-between items-center text-gray-800'>
                      <span>{fileName}</span>
                      <span><MdDelete onClick={removeImage} className='hover:text-red-500 cursor-pointer' /></span>
                    </p>
                  )}
                </div>

                <div className='ml-4 mt-2'>
                  <label htmlFor="">Name</label><br />
                  <input
                    type='text'
                    id='name'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2`}
                  />
                  {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                </div>

                <div className='ml-4 mt-2'>
                  <label htmlFor="">Phone</label><br />
                  <input
                    type='text'
                    id='mobile'
                    name='mobile'
                    value={formData.mobile}
                    onChange={handleInputChange}
                    className={`w-full border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2`}
                  />
                  {errors.mobile && <p className='text-red-500 text-sm'>{errors.mobile}</p>}
                </div>

                <div className='ml-4 mt-2'>
                  <label htmlFor="">Location</label><br />
                  <div className="relative">
                    <Autocomplete className={`focus:outline-none border p-2 w-full rounded ${errors.location ? 'border-red-500' : 'border-gray-300'}`}
                      apiKey={'AIzaSyCqS-_TBx6GtHf-PSr7Ia2dNDhNVWkPPH4'}
                      onPlaceSelected={(place) => {
                        setLocation(place.formatted_address);
                      }}
                      defaultValue={formData.location} // Set default value if needed
                    />
                    {errors.location && <p className='text-red-500 text-sm'>{errors.location}</p>}
                  </div>
                </div>

                <div className='ml-4 mt-2'>
                  <label htmlFor="">Bio</label><br />
                  <textarea
                    id='bio'
                    name='bio'
                    value={formData.bio}
                    onChange={handleInputChange}
                    className='w-full border border-gray-300 rounded px-3 py-2'
                  />
                </div>

                <div className='ml-4 mt-6'>
                  <button type='submit' className='bg-green-800 text-white p-3 font-bold rounded-xl w-full lg:w-36'>Save Profile</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Editprofile