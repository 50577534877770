import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { Dropdown } from 'react-bootstrap';

function Stable() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [Name, setName] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }

  return (
    <div className='bg-gray-100 pb-16'>
      <div className='container py-2 flex flex-wrap justify-between items-center'>
        <ul className='flex flex-wrap gap-4 md:gap-8'>
          {headerMenu.map((item, index) => (
            <Link key={index} to={item.path} className='no-underline'>
              <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                <span className='text-black text-sm md:text-xl'>{item.link}</span>
              </li>
            </Link>
          ))}
        </ul>
        <div className="relative inline-block text-left">
          {showLoginPopup
            ? <div
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
            >
              <span className='text-xl md:text-3xl'><CiUser /></span>
              <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
              <p className='text-xl my-auto'><FaAngleDown /></p>
              {isOpen && (
                <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                    <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                    <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                    <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                    <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                    <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                  </div>
                </div>
              )}
            </div>
            : <div
              className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
              <Link to={'/login'} className='text-green-600 font-bold'>
                <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                  Login
                </div>
              </Link>
            </div>
          }
        </div>
      </div>

      <div onClick={checkLoginInfo} className='max-w-screen-md mx-auto mt-8 text-center px-4 sm:px-6 lg:px-8'>
        <img className='w-40 mx-auto' src="https://www.whickr.com/img/empty-stable.png" alt="Empty Stable" />
        <h1 className='font-bold mt-2'>Your Stable's empty!</h1>
        <h4 className='text-gray-600'>Not to worry, have a look around and add whatever tickles your fancy.</h4>
      </div>
    </div>
  );
}

export default Stable;
