import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcManager } from "react-icons/fc";
import { Dropdown } from 'react-bootstrap';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

function Changepassword() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [Name, setName] = useState('')

  const navigate = useNavigate()

  const validate = () => {
    const newErrors = {};
    if (!currentPassword) newErrors.currentPassword = 'Current password is required';
    if (!newPassword) newErrors.newPassword = 'New password is required';
    if (!confirmNewPassword) newErrors.confirmNewPassword = 'Confirm new password is required';
    return newErrors;
  };

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    console.log('Form submitted:', { currentPassword, newPassword, confirmNewPassword });
  };

  return (
    <>
      <div className='bg-gray-100'>
      <div className='container py-2 flex flex-wrap justify-between items-center'>
        <ul className='flex flex-wrap gap-4 md:gap-8'>
          {headerMenu.map((item, index) => (
            <Link key={index} to={item.path} className='no-underline'>
              <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                <span className='text-black text-sm md:text-xl'>{item.link}</span>
              </li>
            </Link>
          ))}
        </ul>
        <div className="relative inline-block text-left">
          {showLoginPopup
            ? <div
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
            >
              <span className='text-xl md:text-3xl'><CiUser /></span>
              <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
              <p className='text-xl my-auto'><FaAngleDown /></p>
              {isOpen && (
                <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                    <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                    <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                    <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                    <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                    <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                  </div>
                </div>
              )}
            </div>
            : <div
              className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
              <Link to={'/login'} className='text-green-600 font-bold'>
                <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                  Login
                </div>
              </Link>
            </div>
          }
        </div>
      </div>

        <div onClick={checkLoginInfo} className='max-w-[1000px] mx-auto pt-4 pb-4'>
          <div className='flex flex-col md:flex-row px-4 md:px-20 gap-8'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl lg:mb-0 p-4'>
              <div className='space-y-4'>
                <Link to={'myaccount'} className='no-underline'>
                  <h1 className='text-lg text-[#000000D9]'>My Account</h1>
                </Link>
                <ol className='list-none pl-0'>
                  <Link to={'/editprofile'} className='text-[#377F63] no-underline'>
                    <li className='text-green-800 flex gap-2 mt-2'>
                      <span>Edit Profile</span>
                    </li>
                  </Link>
                  <Link to={"/changeemail"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Email</li>
                  </Link>
                  <Link to={"/changepassword"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Password</li>
                  </Link>
                </ol>

                <div className='mt-2'>
                  <h1 className='text-lg text-[#000000D9]'>My Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={"/all"} className='text-[#377F63] no-underline'>
                      <li className='text-green-800 flex gap-2 mt-2 items-center'>
                        <span>All</span>
                        <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                      </li>
                    </Link>
                    <Link to={"/live"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                    <Link to={"/expried"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Expired</li>
                    </Link>
                    <Link to={"/sold"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Sold</li>
                    </Link>
                  </ol>
                </div>
                <div className='mt-2'>
                  <h1 className='text-lg text-[#000000D9]'>My Wanted Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={"/mywantedadvert"} className='text-[#377F63] no-underline'>
                      <li className='text-green-800 flex gap-2 mt-2 items-center'>
                        <span>All</span>
                        <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                      </li>
                    </Link>
                    <Link to={"/mywantedadvertlive"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                    <Link to={"/mywantedadvertexpried"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Expired</li>
                    </Link>
                    <Link to={"/mywantedadvertdraft"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Draft</li>
                    </Link>
                  </ol>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-8/12'>
              <div className='shadow-xl bg-white rounded-xl px-6 py-5 lg:py-20'>
                <h1 className='text-center text-xl md:text-2xl mb-4'>Change Password</h1>
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col'>
                      <label htmlFor='currentPassword' className='text-lg'>Current Password</label>
                      <input
                        type='password'
                        id='currentPassword'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className={`border ${errors.currentPassword ? 'border-red-500' : 'border-gray-300'} rounded p-2`}
                      />
                      {errors.currentPassword && <span className='text-red-500'>{errors.currentPassword}</span>}
                    </div>
                    <div className='flex flex-col'>
                      <label htmlFor='newPassword' className='text-lg'>New Password</label>
                      <input
                        type='password'
                        id='newPassword'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={`border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} rounded p-2`}
                      />
                      {errors.newPassword && <span className='text-red-500'>{errors.newPassword}</span>}
                    </div>
                    <div className='flex flex-col'>
                      <label htmlFor='confirmNewPassword' className='text-lg'>Confirm New Password</label>
                      <input
                        type='password'
                        id='confirmNewPassword'
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className={`border ${errors.confirmNewPassword ? 'border-red-500' : 'border-gray-300'} rounded p-2`}
                      />
                      {errors.confirmNewPassword && <span className='text-red-500'>{errors.confirmNewPassword}</span>}
                    </div>
                    <button type='submit' className='bg-green-600 text-white p-2 rounded mt-2'>Change Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Changepassword;