import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Marketplace from './components/HeroSection/Marketplace';
import Blog from './components/Blog';
import BlogDetail from './components/BlogDetail';
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus';
import Browse from './components/Browse';
import Stable from './components/Stable';
import Footer from './components/Footer';
import Createadvert from './components/Createadvert';
import Createwantedadvert from './components/Createwantedadvert';
import Hiamit from './components/Hiamit';
import Popup from './components/Popup';
import Login from './components/Login';
import View from './components/View';
import Myadvert from './components/Myadvert';
import Mywantedadvert from './components/Mywantedadvert';
import Editprofile from './components/Editprofile';
import Changeemail from './components/Changeemail';
import Changepassword from './components/Changepassword';
import Notification from './components/Notification';
import Logout from './components/Logout';
import Expried from './components/Expried';
import Draft from './components/Draft';
import Sold from './components/Sold';
import All from './components/All';
import Live from './components/Live';
import Lives from './components/Lives';
import ShowPostSection from './components/ShowPostSection/ShowPostSection';
import SignUp from './components/SignUp';
import List from './components/List';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const userEmail = localStorage.getItem('userEmail');
  const userPassword = localStorage.getItem('userPassword');
  const isAuthenticated = userEmail && userPassword;

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Marketplace />} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/blog/:slug' element={<BlogDetail />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/showPostSection/:id" element={<ShowPostSection />} />
          <Route path='/list' element={<List />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/stable" element={<Stable />} />
          <Route path="/createadvert" element={<Createadvert />} />
          <Route path="/createwantedadvert" element={<Createwantedadvert />} />
          <Route path="/hiamit" element={<Hiamit />} />
          <Route path="/popup" element={<Popup />} />
          <Route
            path="/login"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectPath="/" />
            }
          >
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/view" element={<View />} />
          <Route path="/myadvert" element={<Myadvert />} />
          <Route path="/mywantedadvert" element={<Mywantedadvert />} />
          <Route path="/editprofile" element={<Editprofile />} />
          <Route path="/changeemail" element={<Changeemail />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route path="/logout" element={<Logout />} />

          <Route
            path="/signup"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectPath="/" />
            }
          >
            <Route path='/signup' element={<SignUp />} />
          </Route>



          <Route path="/all" element={<All />} />
          <Route path="/live" element={<Live />} />
          <Route path="/expried" element={<Expried />} />
          <Route path="/draft" element={<Draft />} />
          <Route path="/sold" element={<Sold />} />
          <Route path="/lives" element={<Lives />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;