import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { FaToggleOff } from "react-icons/fa";
import 'primeicons/primeicons.css';
import { Dropdown } from 'react-bootstrap';
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

function Changeemail() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [Name, setName] = useState('')
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';
    return newErrors;
  };

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    // Handle form submission
    console.log('Form submitted:', { email });
  };

  return (
    <>
      <div className='bg-gray-100'>
      <div className='container py-2 flex flex-wrap justify-between items-center'>
        <ul className='flex flex-wrap gap-4 md:gap-8'>
          {headerMenu.map((item, index) => (
            <Link key={index} to={item.path} className='no-underline'>
              <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                <span className='text-black text-sm md:text-xl'>{item.link}</span>
              </li>
            </Link>
          ))}
        </ul>
        <div className="relative inline-block text-left">
          {showLoginPopup
            ? <div
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
            >
              <span className='text-xl md:text-3xl'><CiUser /></span>
              <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
              <p className='text-xl my-auto'><FaAngleDown /></p>
              {isOpen && (
                <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                    <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                    <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                    <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                    <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                    <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                  </div>
                </div>
              )}
            </div>
            : <div
              className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
              <Link to={'/login'} className='text-green-600 font-bold'>
                <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                  Login
                </div>
              </Link>
            </div>
          }
        </div>
      </div>

        <div onClick={checkLoginInfo} className='max-w-[1000px] mx-auto pt-4 pb-4'>
          <div className='flex flex-col md:flex-row px-4 md:px-20 gap-8'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl p-4'>
              <h1 className='text-lg text-[#000000D9]'>My Account</h1>
              <ol className='list-none pl-0'>
                <Link to={"/editprofile"} className='text-[#377F63] no-underline'>
                  <li className='text-green-800 flex gap-2 mt-2'>
                    <span>Edit Profile</span>
                  </li>
                </Link>
                <Link to={"/changeemail"} className='text-[#377F63] no-underline'>
                  <li className='mt-1'>Change Email</li>
                </Link>
                <Link to={"/changepassword"} className='text-[#377F63] no-underline'>
                  <li className='mt-1'>Change Password</li>
                </Link>
              </ol>

              <div className='mt-2'>
                <h1 className='text-lg text-[#000000D9]'>My Adverts</h1>
                <ol className='list-none pl-0'>
                  <Link to={"/all"} className='text-[#377F63] no-underline'>
                    <li className='text-green-800 flex gap-2 mt-2 items-center'>
                      <span>All</span>
                      <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                    </li>
                  </Link>
                  <Link to={"/live"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Live</li>
                  </Link>
                  <Link to={"/expried"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Expired</li>
                  </Link>
                  <Link to={"/sold"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Sold</li>
                  </Link>
                </ol>
              </div>
              <div>
                <h1 className='text-lg text-[#000000D9]'>My Wanted Adverts</h1>
                <ol className='list-none pl-0'>
                  <Link to={'/live'} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Live</li>
                  </Link>
                </ol>
              </div>
            </div>

            <div className='w-full md:w-8/12 bg-white rounded-lg shadow-2xl'>
              <div className='p-6'>
                <form onSubmit={handleSubmit}>
                  <h1 className='text-[16px] text-[#000000D9]'>Change your email</h1>
                  <p className='text-[16px] text-[#000000D9]'>We will need to verify your new email address.</p>
                  <div className='mt-2'>
                    <label htmlFor="email" className='ml-1'>Email</label><br />
                    <input
                      className={`border-2 p-2 rounded-xl w-full md:w-72 mt-1 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <p className='text-red-500 mt-1'>{errors.email}</p>}
                  </div>
                  <div>
                    <button type='submit' className='bg-green-800 text-white p-3 font-bold rounded-xl w-full md:w-36 mt-8'>
                      Change Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Changeemail;
