import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { Dropdown } from 'react-bootstrap';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

function Draft() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');

    if (userEmail && userPassword) {
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }
  return (
    <>
      <div className='bg-gray-100'>
        <div className='container py-2 flex justify-around items-center'>
          <ul className='flex text-center gap-8'>
            {headerMenu.map((item, index) => (
              <Link to={item.path} className='no-underline'> <li
                key={index}
                className='md:flex lg:flex xl:flex items-center gap-3 hover:text-green-700 cursor-pointer'
              >
                <img className='w-6 h-6' src={item.icon} alt={item.link} />
                <span className='text-black md:text-xl'>{item.link}</span>
              </li></Link>
            ))}
          </ul>
          <div className="relative inline-block text-left">

            {
              showLoginPopup
                ?
                <div
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                  className="cursor-pointer mt-2 rounded-xl"
                >
                  <div className='flex items-center gap-2'>
                    <span className='text-3xl md:block hidden pb-3'><CiUser /></span>
                    <p className='font-semibold md:block hidden text-[16px]'>Hi amit</p>
                    <p className='text-xl mt-1'><FaAngleDown /></p>
                  </div>
                  {isOpen && (
                    <div className="absolute z-20 right-0 mt-2 w-56 top-10 left-3 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <Link className='no-underline' to={'/myadvert'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Adverts</li></Link>
                        <Link className='no-underline' to={'/mywantedadvert'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Wanted Adverts</li> </Link>
                        <Link className='no-underline' to={'/editprofile'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Edit Profile</li> </Link>
                        <Link className='no-underline' to={'/changeemail'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Email</li></Link>
                        <Link className='no-underline' to={'/changepassword'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Password</li></Link>
                        <Link className='no-underline' to={'/notification'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Notifications</li></Link>
                        <Link className='no-underline' to={'/logout'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</li></Link>
                      </div>
                    </div>
                  )}
                </div>
                :
                <div
                  className='relative cursor-pointer bg-gray-100 p-2 rounded'
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                  <Link to={'/login'} className='text-green-600 font-bold'>
                    <div className={`absolute bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                      Login
                    </div>
                  </Link>
                </div>
            }

          </div>
        </div>

        <div onClick={checkLoginInfo} className='max-w-[1000px] mx-auto px-4 pb-8'>
          <div className='lg:flex  lg:flex-nowrap gap-8'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl lg:mb-0 p-4'>
              <div className='space-y-2'>
                <Link to={'myaccount'} className='no-underline'>
                  <h1 className='text-lg text-[#000000D9]'>My Account</h1>
                </Link>
                <ol className='list-none pl-0'>
                  <Link to={'/editprofile'} className='text-[#377F63] no-underline'>
                    <li className='text-green-800 flex gap-2 mt-2'>
                      <span>Edit Profile</span>
                    </li>
                  </Link>
                  <Link to={"/changeemail"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Email</li>
                  </Link>
                  <Link to={"/changepassword"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Password</li>
                  </Link>
                  <Link to={"/notification"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Notifications</li>
                  </Link>
                </ol>

                <div className='mt-2'>
                  <h1 className='text-lg text-[#000000D9]'>My Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={"/all"} className='text-[#377F63] no-underline'>
                      <li className='text-green-800 flex gap-2 mt-2 items-center'>
                        <span>All</span>
                        <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                      </li>
                    </Link>
                    <Link to={"/live"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                    <Link to={"/expried"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Expired</li>
                    </Link>
                    <Link to={"/draft"} className='text-[#377F63] no-underline'>
                      <li className='mt-1 flex gap-2 items-center'>
                        <span>Draft</span>
                        <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                      </li>
                    </Link>
                    <Link to={"/sold"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Sold</li>
                    </Link>
                  </ol>
                </div>
                <div>
                  <h1 className='text-lg text-[#000000D9]'>My Wanted Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={'/live'} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                  </ol>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-8/12'>
              <h1 className='rounded-xl shadow-2xl bg-white text-[18px] p-3'>Draft Adverts</h1>
              <div className='flex flex-wrap'>
                <div className='w-full lg:w-8/12 bg-white shadow-2xl ml-0 mt-8 rounded-2xl pb-4'>
                  <div className='relativ p-2'>
                    <img src="https://d4i9b05ppmspg.cloudfront.net/90cf816b-ca62-48e9-b21e-9b4590d57288-lg@1x.webp" alt="" />
                    <div className='btn_like absolute top-4 right-4'>
                      <img src="https://www.whickr.com/img/heartEmptyIcon.svg" className='bg-white p-2 w-12 h-12 rounded-3xl' alt="" />
                    </div>
                    <div className='flex h-full mt-2 ps-4 md:ps-10 font-bold'>
                      <h1 className='mt-1 text-green-800 text-[20px]'>£10</h1>
                      <button className='bg-[#F0EEF8] rounded-xl px-3 ml-3 text-center text-sm text-[#695BBC] cursor-pointer'>Sale</button>
                    </div>
                    <div className='ml-6'>
                      <h1 className='mt-1 text-[18px]'>dsdsd</h1>
                      <p className='text-gray-400 text-[16px]'>ok in London</p>
                    </div>
                    <div className='flex items-center space-x-2 mt-6 text-green-800 ml-6'>
                      <h1 className='text-[18px]'>10yrs</h1>
                      <hr className='w-0.5 h-5 bg-green-700' />
                      <h6 className='text-[18px]'>10hh</h6>
                      <hr className='w-0.5 h-5 bg-green-700' />
                      <h1 className='text-[18px]'>Mare</h1>
                      <div className='ml-8'>
                        <a href="/view" className='flex items-center'>
                          <img className='ml-2 mt-2' src="https://www.whickr.com/img/arrowRightIcon.svg" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Draft;
