import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function BlogDetail() {
    const [blog, setBlog] = useState(null);
    const { slug } = useParams();
    const blogDetailAPI = process.env.REACT_APP_BLOG_DETAIL_API_URL;

    useEffect(() => {
        axios.post(blogDetailAPI, { slug })
            .then((response) => {
                console.log(response.data.data);
                setBlog(response.data.data);
            })
            .catch((err) => {
                console.log(err, 'error');
            });
    }, [slug]);

    if (!blog) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
            </div>
        )
    }

    return (
        <div className="max-w-4xl mx-auto p-4">
            <div className="bg-white shadow-md rounded-lg p-6">
                <h1 className="text-3xl font-bold mb-4">{blog?.title}</h1>
                <div className='flex gap-1'>
                    <p className="text-gray-600 mb-4">by {blog?.author}</p>
                    <p className="text-gray-600 mb-2">{new Date(blog?.posted_at).toLocaleDateString()}</p>
                </div>
                {blog?.thumbnail && (
                    <img
                        src={`https://crm.commoditylevels.com/${blog?.thumbnail}`}
                        alt={blog?.title}
                        className="w-full h-auto mb-8 object-contain rounded"
                    />
                )}
                <div>{blog.summary}</div>
                {Object.values(blog.content).map((item, index) => (
                    <div key={index}>
                        <div>{item.type}</div>
                        <div>{item.section}</div>
                        <div dangerouslySetInnerHTML={{__html:item.text}}></div>
                    </div>
                ))}
            </div>
        </div>
    );
}
