import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';

function All() {

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [posts, setPosts] = useState();
  const [Name, setName] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }

  const advAPI = process.env.REACT_APP_SHOW_ADV_API_URL;

  useEffect(() => {
    axios.post(advAPI)
      .then(success => {
        setPosts(success.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  if (!posts) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <>
      <div className='bg-gray-100'>
        <div className='container py-2 flex flex-wrap justify-between items-center'>
          <ul className='flex flex-wrap gap-4 md:gap-8'>
            {headerMenu.map((item, index) => (
              <Link key={index} to={item.path} className='no-underline'>
                <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                  <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                  <span className='text-black text-sm md:text-xl'>{item.link}</span>
                </li>
              </Link>
            ))}
          </ul>
          <div className="relative inline-block text-left">
            {showLoginPopup
              ? <div
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
              >
                <span className='text-xl md:text-3xl'><CiUser /></span>
                <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
                <p className='text-xl my-auto'><FaAngleDown /></p>
                {isOpen && (
                  <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                      <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                      <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                      <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                      <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                      <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                    </div>
                  </div>
                )}
              </div>
              : <div
                className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                <Link to={'/login'} className='text-green-600 font-bold'>
                  <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                    Login
                  </div>
                </Link>
              </div>
            }
          </div>
        </div>

        <div onClick={checkLoginInfo} className='max-w-[1000px] mx-auto px-4 pb-8 overflow-hidden'>
          <div className='flex flex-col lg:flex-row gap-8'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl p-4'>
              <div className='space-y-2'>
                <Link to={'/myaccount'} className='no-underline'>
                  <h1 className='text-lg text-[#000000D9]'>My Account</h1>
                </Link>
                <ol className='list-none pl-0'>
                  <Link to={'/editprofile'} className='text-[#377F63] no-underline'>
                    <li className='text-green-800 flex gap-2 mt-2'>
                      <span>Edit Profile</span>
                    </li>
                  </Link>
                  <Link to={"/changeemail"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Email</li>
                  </Link>
                  <Link to={"/changepassword"} className='text-[#377F63] no-underline'>
                    <li className='mt-1'>Change Password</li>
                  </Link>
                </ol>

                <div className='mt-2'>
                  <h1 className='text-lg text-[#000000D9]'>My Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={"/all"} className='text-[#377F63] no-underline'>
                      <li className='text-green-800 flex gap-2 mt-2 items-center'>
                        <span>All</span>
                        <span className='bg-gray-200 w-5 h-5 rounded-xl text-white text-center'>1</span>
                      </li>
                    </Link>
                    <Link to={"/live"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                    <Link to={"/expried"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Expired</li>
                    </Link>
                    <Link to={"/sold"} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Sold</li>
                    </Link>
                  </ol>
                </div>
                <div>
                  <h1 className='text-lg text-[#000000D9]'>My Wanted Adverts</h1>
                  <ol className='list-none pl-0'>
                    <Link to={'/live'} className='text-[#377F63] no-underline'>
                      <li className='mt-1'>Live</li>
                    </Link>
                  </ol>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-8/12'>
              <h1 className='text-[16px] ml-4 rounded-xl shadow bg-white p-3'>Draft Adverts</h1>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                {posts?.map((card, index) => (
                  <Link key={index} to={card.id} className="block relative overflow-hidden rounded-lg shadow-md no-underline">
                    <div className="p-0">
                      <div className="relative w-full h-[200px] bg-cover bg-center">
                        <img
                          src={`https://crm.commoditylevels.com/${card.image}`}
                          alt="Horse post thumbnail"
                          className="object-cover w-full h-full"
                        />
                      </div>
                      <div className="p-2 bg-white">
                        <div className="flex justify-between items-start">
                          <div className="flex flex-col">
                            <div className="flex items-center uppercase gap-3">
                              <h4 className="text-xl font-bold text-green-800">£{card.price}</h4>
                              <span className="bg-purple-100 text-blue-800 px-4 font-semibold rounded-full">{card.sale_type}</span>
                            </div>
                            <p className="block font-bold text-gray-900">{card.title}</p>
                            <p className="text-[16px] text-gray-600">{card.discipline}</p>
                          </div>

                        </div>
                        <div className="flex gap-2 items-center text-xl text-green-800">
                          <li className="md:block lg:block xl:block flex flex-col">{card.age}</li>
                          <li className="md:block lg:block xl:block flex flex-col">|</li>
                          <li className="md:block lg:block xl:block flex flex-col">{card.height}</li>
                          <li className="md:block lg:block xl:block flex flex-col">|</li>
                          <li className="md:block lg:block xl:block flex flex-col">{card.gender}</li>
                        </div>
                        <div className="flex items-centerspace-x-4 relative">
                          <Link to={"/showPostSection"} className='no-underline absolute bottom-1 right-0 hover:text-blue-600'>
                            <div className="flex items-center text-gray-600">
                              <div className="text-xl md:block lg:block xl:block text-green-800">View</div>
                              <span className="text-xl text-green-800 absolute md:top-[157px] lg:top-[157px] xl:top-[157px] top-[150px] right-3"><FiArrowRight /></span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default All;
