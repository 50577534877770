import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import { Link, useNavigate } from 'react-router-dom';
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { Dropdown } from 'react-bootstrap';

function Lives() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');

    if (userEmail && userPassword) {
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }
  return (
    <>
      <div className='bg-gray-200'>
        <div className='container py-2 flex justify-around items-center'>
          <ul className='flex text-center gap-8'>
            {headerMenu.map((item, index) => (
              <Link to={item.path} className='no-underline'> <li
                key={index}
                className='md:flex lg:flex xl:flex items-center gap-3 hover:text-green-700 cursor-pointer'
              >
                <img className='w-6 h-6' src={item.icon} alt={item.link} />
                <span className='text-black md:text-xl'>{item.link}</span>
              </li></Link>
            ))}
          </ul>
          <div className="relative inline-block text-left">

            {
              showLoginPopup
                ?
                <div
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                  className="cursor-pointer mt-2 rounded-xl"
                >
                  <div className='flex items-center gap-2'>
                    <span className='text-3xl md:block hidden pb-3'><CiUser /></span>
                    <p className='font-semibold md:block hidden text-[16px]'>Hi amit</p>
                    <p className='text-xl mt-1'><FaAngleDown /></p>
                  </div>
                  {isOpen && (
                    <div className="absolute z-20 right-0 mt-2 w-56 top-10 left-3 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <Link className='no-underline' to={'/myadvert'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Adverts</li></Link>
                        <Link className='no-underline' to={'/mywantedadvert'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Wanted Adverts</li> </Link>
                        <Link className='no-underline' to={'/editprofile'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Edit Profile</li> </Link>
                        <Link className='no-underline' to={'/changeemail'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Email</li></Link>
                        <Link className='no-underline' to={'/changepassword'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Password</li></Link>
                        <Link className='no-underline' to={'/notification'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Notifications</li></Link>
                        <Link className='no-underline' to={'/logout'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</li></Link>
                      </div>
                    </div>
                  )}
                </div>
                :
                <div
                  className='relative cursor-pointer bg-gray-100 p-2 rounded'
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                  <Link to={'/login'} className='text-green-600 font-bold'>
                    <div className={`absolute bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                      Login
                    </div>
                  </Link>
                </div>
            }

          </div>
        </div>

        <div onClick={checkLoginInfo} className='max-w-[1000px] mx-auto pb-4 pt-4'>
          <div className='flex  lg:flex-nowrap gap-8 px-2 lg:px-0'>
            <div className='w-full lg:w-4/12 shadow-xl bg-white rounded-xl'>
              <div className='p-4 lg:p-8'>
                <h1 className='font-bold'>My account</h1>
                <Link to="/Editprofile" className='block mt-2 text-green-800'>Edit profile</Link>
                <Link to="/Changeemail" className='block mt-1 text-green-800'>Change email</Link>
                <Link to="/Changepassword" className='block mt-1 text-green-800'>Change password</Link>
                <Link to="/Notifications" className='block mt-1 text-green-800'>Notifications</Link>
                <h1 className='mt-8 font-bold'>My advarts</h1>
                <Link to="/All" className='block mt-2 text-green-800'>
                  All <span className='bg-gray-400 w-5 h-5 text-center rounded-xl ml-2 text-white'>1</span>
                </Link>
                <Link to="/Live" className='block mt-2 text-green-800'>Live</Link>
                <Link to="/Expried" className='block mt-2 text-green-800'>Expried</Link>
                <Link to="/Draft" className='block mt-2 text-green-800'>
                  Draft <span className='bg-gray-400 w-5 h-5 text-center rounded-xl ml-2 text-white'>1</span>
                </Link>
                <Link to="/Sold" className='block mt-2 text-green-800'>Sold</Link>
                <h1 className='mt-16 font-bold'>My Wanted Adverts</h1>
                <Link to="/Live" className='block mt-1 text-green-800'>Live</Link>
              </div>
            </div>
            <div className='w-full lg:w-8/12 flex justify-center items-center'>
              <div className='text-center mt-8'>
                <img className='w-24 mx-auto' src="https://www.whickr.com/img/no-adverts.svg" alt="No Wanted Adverts" />
                <h1 className='font-bold mt-2'>No Wanted Adverts</h1>
                <h2>You don't have any wanted adverts on Whickr</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lives;
