import React from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa'; // Corrected the import
import { IoIosArrowUp } from 'react-icons/io'; // Imported but not used in your code

function Footer() {
    return (
        <div className=" bg-slate-50 py-20">
            <div className="max-w-screen-lg   mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
                    <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
                        <img src="https://www.whickr.com/img/whickrWordLogo.svg" alt="Whickr Logo" className="mb-2" />
                        <h1 className="text-green-800">@2023 Whickr Ltd</h1>
                    </div>
                    <div className="flex flex-col items-center md:items-end">
                        <div className="flex flex-col md:flex-row gap-3 mb-6 md:mb-0">
                            <a href="#" className="text-green-800">Terms & Conditions</a>
                            <a href="#" className="text-green-800">Privacy Policy</a>
                            <a href="#" className="text-green-800">Contact</a>
                        </div>
                        <div className="flex gap-6 mt-2">
                            <FaFacebookSquare className="w-8 h-8 text-gray-500 hover:text-green-800 transition duration-300" />
                            <FaInstagramSquare className="w-8 h-8 text-gray-500 hover:text-green-800 transition duration-300" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
