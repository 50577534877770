import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const loginApi = process.env.REACT_APP_LOGIN_API_URL;
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    axios.post(loginApi, { email, password })
      .then(response => {
        console.log('Login successful:', response);

        if (response.data.status === 'true') {
          localStorage.setItem('userEmail', email);
          localStorage.setItem('userPassword', password);
          localStorage.setItem('user_id', response.data.data.id)
          localStorage.setItem('username', response.data.data.name)
          navigate('/');
        }
      })
      .catch(error => {
        console.error('Login failed:', error.response?.data || error.message);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-2 sm:py-8">
      <div className="form_box_login w-full max-w-md bg-white shadow-lg rounded-lg p-4 sm:p-8">
        <form className="space-y-2 sm:space-y-6" onSubmit={handleSubmit}>
          <div className="text-center font-bold text-xl sm:text-2xl text-gray-800">
            <h1>Log in with your Whickr Account</h1>
          </div>
          <div className="form-outline">
            <input
              type="email"
              id="email"
              className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <div className="text-red-600">{errors.email}</div>}
          </div>
          <div className="form-outline">
            <input
              type="password"
              id="password"
              className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && <div className="text-red-600">{errors.password}</div>}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <input
                className="h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-800 checked:border-green-800 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                type="checkbox"
                id="rememberMe"
              />
              <label className=" text-gray-700" htmlFor="rememberMe">
                Remember me
              </label>
            </div>
            <div>
              <a href="#!" className="text-green-800 hover:underline">
                Forgot password?
              </a>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block mt-2 w-full py-2 bg-green-800 text-white font-bold rounded hover:bg-green-700 transition duration-200"
          >
            Log In
          </button>
          <div className="text-center mt-2">
            <Link to={'/signup'}>
              <button
                type="button"
                className="btn btn-secondary btn-block mb-1 w-full py-2 bg-gray-100 text-green-800 font-bold rounded hover:bg-gray-200 transition duration-200"
              >
                Create New Account
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
