import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


export default function SignUp() {
    const [email_id, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mobile, setMobileNumber] = useState('');
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const validate = () => {
        const newErrors = {};
        if (!email_id) newErrors.email_id = 'Email is required';
        if (!password) newErrors.password = 'Password is required';
        if (!confirmPassword) newErrors.confirmPassword = 'Confirm Password is required';
        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        if (!mobile) newErrors.mobile = 'Mobile Number is required';
        if (!name) newErrors.name = 'Name is required';
        if (!bio) newErrors.bio = 'Bio is required';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            email_id,
            mobile,
            name,
            bio,
            password
        };

        axios.post('https://crm.commoditylevels.com/api/register', payload)
            .then((response) => {
                console.log(response.data)
                if (response.data.status === 'true') {
                    setSuccessMessage(response.data.message);
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setMobileNumber('');
                    setName('');
                    setBio('');
                    setTermsAccepted(false);
                    const timer = setTimeout(() => {
                        navigate('/login');
                    }, 3000);

                    return () => clearTimeout(timer);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const handleCheckboxChange = (e) => {
        setTermsAccepted(e.target.checked);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-2 sm:py-8">
            <div className="form_box_signup w-full max-w-md bg-white shadow-sm rounded-lg p-4 sm:p-8">
                <form className="space-y-2 sm:space-y-4" onSubmit={handleSubmit}>
                    <div className="text-center font-bold sm:text-2xl text-gray-800">
                        <h1>Create a New Account</h1>
                    </div>
                    <div className="form-outline">
                        <input
                            type="text"
                            id="name"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <div className="text-red-600">{errors.name}</div>}
                    </div>
                    <div className="form-outline">
                        <input
                            type="email"
                            id="email"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Email"
                            value={email_id}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email_id && <div className="text-red-600">{errors.email_id}</div>}
                    </div>
                    <div className="form-outline">
                        <input
                            type="tel"
                            id="mobile"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                        {errors.mobile && <div className="text-red-600">{errors.mobile}</div>}
                    </div>
                    <div className="form-outline">
                        <input
                            type="text"
                            id="bio"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Bio"
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                        />
                        {errors.bio && <div className="text-red-600">{errors.bio}</div>}
                    </div>
                    <div className="form-outline">
                        <input
                            type="password"
                            id="password"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && <div className="text-red-600">{errors.password}</div>}
                    </div>
                    <div className="form-outline">
                        <input
                            type="password"
                            id="confirmPassword"
                            className="form-control w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-800"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {errors.confirmPassword && <div className="text-red-600">{errors.confirmPassword}</div>}
                    </div>
                    <div className="flex items-center space-x-2">
                        <input
                            className={` h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 align-top mr-2 cursor-pointer ${termsAccepted ? 'bg-green-800 checked:bg-green-800' : ''}`}
                            type="checkbox"
                            id="terms"
                            onChange={handleCheckboxChange}
                            checked={termsAccepted}
                        />
                        <label className="form-check-label text-gray-700" htmlFor="terms">
                            Agree to terms and conditions
                        </label>
                    </div>
                    <button
                        type="submit"
                        className={`btn btn-primary btn-block mt-2 w-full py-2 ${termsAccepted ? 'bg-green-800 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'} font-bold rounded hover:${termsAccepted ? 'bg-green-700' : 'bg-gray-300'} transition duration-200`}
                        disabled={!termsAccepted} // Disable the button if terms are not accepted
                    >
                        Sign Up
                    </button>
                    {successMessage && (
                        <div className="mt-2 text-2xl font-bold fixed bottom-5 right-10 p-6 bg-green-100 text-green-800 border border-green-300 rounded">
                            {successMessage}
                        </div>
                    )}
                    <div className="text-center mt-2">
                        <Link to={'/login'}>
                            <button
                                type="button"
                                className="btn btn-secondary btn-block mb-1 w-full py-2 bg-gray-100 text-green-800 font-bold rounded hover:bg-gray-200 transition duration-200"
                            >
                                Already have an account? Log In
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
