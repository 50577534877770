import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1700,
    cssEase: "linear"
};

function View() {
    return (
        <>
            <div className='bg-gray-100'>
                <div className='max-w-screen-lg mx-auto px-4 sm:px-6 pb-8'>
                    <div className='shadow-xl bg-white'>

                        <div className="slider-container  mx-auto  ">
                            <Slider {...settings}>
                                <div>
                                    <img style={{ height: "550px" }} className='w-full  sm:h-96' src="https://d4i9b05ppmspg.cloudfront.net/F00AB87E-499C-4E4E-98B0-266E4C8771F1-lg@1x.webp" alt="" />
                                </div>
                                <div>
                                    <img style={{ height: "550px" }} className='w-full  sm:h-96' src="https://d4i9b05ppmspg.cloudfront.net/9CAB0D75-F037-4111-94E1-0D8A4996E92C-md@1x.webp" alt="" />
                                </div>
                                <div>
                                    <img style={{ height: "550px" }} className='w-full sm:h-96' src="https://d4i9b05ppmspg.cloudfront.net/B64BF9FB-FFC7-4751-97F2-5FCD7A165B56-md@1x.webp" alt="" />
                                </div>
                            </Slider>
                        </div>

                        <div className='mt-5 px-4 sm:px-8'>
                            <div className='flex items-center bg-white'>
                                <h1 className='text-green-800 font-bold text-2xl'>£12,000</h1>
                                <button className='ml-4 bg-slate-500 text-blue-200 rounded-xl p-1'>Sale</button>
                            </div>
                            <div className='font-bold text-green-800 mt-2'>
                                <h1 className='text-2xl mt-2'>Gorgeous PC All rounder</h1>
                                <h1 className='mt-2'>Clare Leonard in Sidmouth</h1>
                            </div>
                            <div className='flex font-bold gap-8 mt-8'>
                                <div>
                                    <button className='bg-green-50 p-3 rounded-xl text-green-800'>Contact Seller</button>
                                </div>
                                <div>
                                    <button className='bg-green-50 p-3 rounded-xl text-green-800'>Share</button>
                                </div>
                            </div>
                            <div className='flex font-bold gap-8 mt-12'>
                                <div>
                                    <h1 className='text-gray-500'>Height</h1>
                                    <h2 className='text-xl'>14.3hh</h2>
                                </div>
                                <div>
                                    <h1 className='text-gray-500'>Age</h1>
                                    <h2 className='text-xl'>11 years old</h2>
                                </div>
                                <div>
                                    <h1 className='text-gray-500'>Color</h1>
                                    <h2 className='text-xl'>Bay Roan</h2>
                                </div>
                            </div>
                            <div className='flex font-bold gap-8 mt-12'>
                                <div>
                                    <h1 className='text-gray-500'>Breed</h1>
                                    <h2 className='text-xl'>Welsh x TB</h2>
                                </div>
                                <div>
                                    <h1 className='text-gray-500'>Gender</h1>
                                    <h2 className='text-xl'>Mare</h2>
                                </div>
                                <div>
                                    <h1 className='text-gray-500'>Type</h1>
                                    <h2 className='text-xl'>Horse</h2>
                                </div>
                            </div>
                            <div className='font-bold mt-12'>
                                <h1 className='text-gray-500'>Discipline</h1>
                                <h1 className='text-xl'>All Rounder</h1>
                            </div>
                            <div className='font-bold mt-12'>
                                <h1 className='text-gray-500'>Description</h1>
                            </div>
                            <div className=' mt-5'>
                                <p>We have owned Puzzle for 4 years and have done a bit of everything with her. She has
                                    brought lots of smiles to all the family, plenty of rosettes and is a total gem!</p>
                                <p className='mt-2 '>I cannot speak highly enough of her gorgeous character. She is a dream pony. So safe, 100% on
                                    the ground, catch, clip, hack, shoe, box etc. Often used by Pony Club as the demo pony in and out of
                                    the stable. Will happily have a beginner or tiny tot on her and be lead around for fun.</p>
                                <p className='mt-2 '>She is a true all rounder who loves to jump (up to 90cm) but is also very nice on the flat. She’s been
                                    to PC and Adult riding camps and is impeccable on the hunting
                                    field too. She awesome out on XC, so ODEs and rallies are her dream jobs!</p>
                                <p className='mt-2 '>Her accomplishments include winning PC Area Dressage, qualifying PC SJ Regionals and qualifying for
                                    NSEA Hickstead 80cm SJ and NSEA Addington in Prelim Dressage. She is
                                    always highly placed in Prelim dressage competition and in showing
                                    classes (Coloured, Family Pony and Young Rider).</p>
                                <p className='mt-2 '>Puzzle does have mild sweetitch. Managed by rugging and face mask 24/7 April to October.</p>
                                <p>We are selling because she's outgrown by our two daughters. She's in her prime and deserves to give another family the fun we've had.</p>
                            </div>

                        </div>

                        <div className='mt-10  sm:px-8 pb-16 ml-40'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.43602644143!2d75.62574649726879!3d26.88542138946675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1718604937828!5m2!1sen!2sin"
                                width={650}
                                height={450}
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                        <div className='mt-10 pt-16 '>
                            <h1 className='text-2xl text-green-800 text-center '>Report Advert</h1>
                        </div>

                    </div>

                </div>

            </div>
        </>
    );
}

export default View;
