import React from 'react';

function Aboutus() {
  return (
    <>
      <div className='bg-gray-100'>
        <div className=' max-w-[1100px] mx-auto px-6 md:px-16 py-12 md:py-12 '>
          <div className='shadow-2xl bg-white h-full flex flex-col md:flex-row md:gap-8 pb-8'>
            <div className='md:w-6/12 flex gap-8 mt-8 md:mt-28 ml-12'>
              <img className='h-24' src="https://www.whickr.com/img/carrot.png" alt="" />
              <img className='h-24' src="https://www.whickr.com/img/saddle.png" alt="" />
              <img className='h-24' src="https://www.whickr.com/img/rosette.png" alt="" />
            </div>
            <div className='md:w-6/12  md:me-4 ml-12 '>
              <div>
                <h1 className='font-bold text-xl md:text-2xl mt-6 md:mt-10'>Our Story</h1>
                <h2 className='text-gray-600 text-xl mt-3'>Finding the right horse is hard</h2>
                <p className='mt-3'>We’ve all been there; scouring over multiple websites, tweaking filters, paying subscriptions,
                  or searching on Facebook. Having helped family & friends find their perfect horse, we had a light
                  bulb moment! We resolved to address the problem of finding horses for sale for as many people as we can.
                  The best way we can do just that is by giving you an app that puts horses and ponies at your
                  fingertips. Whickr is the app to make finding a horse for sale easier.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-[1100px] mx-auto px-6 md:px-16 py-10 bg-gray-100'>
          <div className='shadow-2xl bg-white p-6 -mt-12'>
            <div className='flex flex-col  md:flex-row gap-8'>
              <div className='md:w-6/12'>
                <h1 className='font-bold text-xl md:text-2xl'>Meet the Founders</h1>
                <p className='mt-2'>We're a group of like-minded equestrians, software engineers and entrepreneurs with a
                  shared ambition to shake things up and to make buying a horse easier and more fun for everyone.</p>
              </div>
              <div className='md:w-6/12 '>
                <h1 className='font-bold text-2xl md:text-3xl  md:mt-1 text-center text-green-800'>"Our promise is simple: <br />
                  Find the horse for you, <br />
                  without the hassle."</h1>
              </div>
            </div>
            <div className='lg:flex justify-center  mt-8 md:mt-12 gap-3'>
              <div className='w-full md:w-4/12 border-gray-400 border-2 rounded-xl p-4 '>
                <img className='h-36 rounded-full mx-auto ' src="https://www.whickr.com/img/andrewamy.jpg" alt="" />
                <h1 className='font-bold text-xl text-green-800 mt-2 text-center'>Andrew Amy</h1>
                <h1 className='font-bold text-center'>CEO</h1>
                <p className='ml-2 mt-2'>Andrew enjoyed a successful ten year long career in the investment industry before pivoting his focus towards his life long passion, equestrianism. In his previous role, he was a senior member of the investment team, the asset allocation and fund selection committee, and head of trading for a notable FinTech startup that was acquired by a FTSE 100 company. Having grown up in the equestrian community, horses are never too far away. He remains
                  a competitive amateur, and enjoys taking part in eventing and showjumping competitions.</p>
              </div>
              <div className='w-full md:w-4/12 border-gray-400 border-2 rounded-xl p-4'>
                <img className='h-36 rounded-full mx-auto ' src="https://www.whickr.com/img/brookeallan.jpg" alt="" />
                <h1 className='font-bold text-xl text-green-800 mt-2 text-center'>Brooke Allan</h1>
                <h1 className='font-bold text-center'>COO</h1>
                <p className='ml-2 mt-2'>Brooke has spent her entire career in roles focused on helping customers: speaking directly with customers, hearing their problems, finding solutions, and delivering customer satisfaction. In her previous role, Brooke was the Head of Customer Care for a leading FemCare startup in the UK. Growing up, the closest Brooke could get to horses was by sneaking into the field up the road, which was home to a pony called Raffles. This ignited her love of horses, and now
                  Brooke is a keen equestrian who enjoys showing, fun rides, and trail hunting.</p>
              </div>
              <div className='w-full md:w-4/12 border-gray-400 border-2 rounded-xl p-4'>
                <img className='h-36 rounded-full mx-auto mt-5' src="https://www.whickr.com/img/leonjames.jpg" alt="" />
                <h1 className='font-bold text-xl text-green-800 mt-2 text-center'>Leon James</h1>
                <h1 className='font-bold text-center'>Strategic Advisor</h1>
                <p className='ml-2 mt-2'>Leon has enjoyed a successful career in the technology sector working for large companies and startups, running teams of software engineers. He met Andrew for a coffee one day, and loved the idea so much he’s been an essential member of the team ever since. When he’s not tinkering with technology
                  Leon likes to write music, and spend time with his family.</p>
              </div>
            </div>
            <div className='lg:flex gap-3  mt-2 md:ml-52 pb-12'>
              <div className='w-full md:w-6/12 border-gray-400 -ms-24 border-2 rounded-xl p-2'>
                <img className='h-36 rounded-full mx-auto mt-3' src="https://www.whickr.com/img/davidadams.png" alt="" />
                <h1 className='font-bold text-xl text-green-800 mt-2 text-center'>David Adams</h1>
                <h1 className='font-bold text-center'>CTO</h1>
                <p className='ml-2 mt-2'>In partnership with Mike, he co-founded a successful software agency, leading notable projects for esteemed clients in the healthcare, financial and education sectors. One of their standout projects was the initial development of the MVP for Whickr. From that point, Dave has played a pivotal role in advancing and refining the platform to where it stands today, guiding the technical aspects while possessing a keen understanding for product development. This balanced perspective ensures that technological
                  advancements align seamlessly with the platforms product goals.</p>
              </div>
              <div className='w-full md:w-6/12 border-gray-400 border-2 rounded-xl p-2'>
                <img className='h-36 rounded-full mx-auto mt-3' src="https://www.whickr.com/img/michaeladams.png" alt="" />
                <h1 className='font-bold text-xl text-green-800 mt-2 text-center'>Michael Adams</h1>
                <h1 className='font-bold text-center'>CPO</h1>
                <p className='ml-2 mt-2'>Alongside Dave, he co-founded a successful software agency, leading notable projects for esteemed clients in the healthcare, financial and education sectors. One of their standout projects was the initial development of the MVP for Whickr. Since then, Mike has been instrumental in evolving and enhancing the platform to its current stature. With a keen eye for detail, his strength lies in identifying user needs and molding them into intuitive and visually captivating products. Mike marries tech expertise
                  with a product focus, driving innovation and user satisfaction.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-gray-100'>
          <div className='max-w-screen-xl mx-auto px-6 md:px-16 py-4 md:py-20'>
            <div className='bg-white shadow-2xl w-full h-72 py-4 md:py-8 px-4 md:px-12'>
              <div className='text-center'>
                <h1 className='text-2xl md:text-3xl'>Sign up to our mailing list!</h1>
              </div>
              <div className=' mt-8 md:mt-8 flex flex-col md:flex-row justify-center items-center gap-4'>
                <input className='border-gray-400 border-2 p-2 md:py-3 md:px-4 mt-2 rounded-xl mb-4 md:mb-0' type="text" placeholder='First Name' name="" id="" />
                <input className='border-gray-400 border-2 p-2 md:py-3 md:px-4 rounded-xl  md:ml-4' type="text" placeholder='Email' name="" id="" />
                <button className='bg-green-800 w-full md:w-auto p-3 md:px-8 text-white font-bold rounded-xl  md:mt-0'>Sign Up!</button>
              </div>
              <div className='text-center mt-8'>
                <p className='text-gray-600'>By clicking submit above, you consent to allow Whickr to store and process the
                  personal <br />information submitted above to provide you the content requested.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Aboutus;
