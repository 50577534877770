import React, { useState } from 'react'
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { Link } from 'react-router-dom'

export default function List() {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

    const headerMenu = [
        { icon: "https://www.whickr.com/img/browseIcon.svg", path: "Browse", link: "Browse" },
        { icon: "https://www.whickr.com/img/stableIcon.svg", path: "Stable", link: "Stable" },
        { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "Createadvert", link: "Create Advert" },
        { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "Wanted", link: "Wanted" },
      ];
    return (
        <div className='container py-2 flex justify-around items-center'>
            <ul className='flex text-center gap-8'>
                {headerMenu.map((item, index) => (
                    <Link to={item.path} className='no-underline'> <li
                        key={index}
                        className='md:flex lg:flex xl:flex items-center gap-3 hover:text-green-700 cursor-pointer'
                    >
                        <img className='w-6 h-6' src={item.icon} alt={item.link} />
                        <span className='text-black md:text-xl'>{item.link}</span>
                    </li></Link>
                ))}
            </ul>
            <div className="relative inline-block text-left">

                {
                    showLoginPopup
                        ?
                        <div
                            onMouseEnter={() => setIsOpen(true)}
                            onMouseLeave={() => setIsOpen(false)}
                            className="cursor-pointer bg-gray-100 p-2 rounded"
                        >
                            <div className='flex items-center gap-2'>
                                <span className='text-3xl md:block hidden pb-3'><CiUser /></span>
                                <p className='font-semibold md:block hidden text-[16px]'>Hi amit</p>
                                <p className='text-xl mt-1'><FaAngleDown /></p>
                            </div>
                            {isOpen && (
                                <div className="absolute z-20 right-0 mt-2 w-56 top-10 left-3 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="py-1">
                                        <Link className='no-underline' to={'myadvert'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Adverts</li></Link>
                                        <Link className='no-underline' to={'mywantedadvert'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">My Wanted Adverts</li> </Link>
                                        <Link className='no-underline' to={'editprofile'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Edit Profile</li> </Link>
                                        <Link className='no-underline' to={'changeemail'}> <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Email</li></Link>
                                        <Link className='no-underline' to={'changepassword'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Change Password</li></Link>
                                        <Link className='no-underline' to={'notification'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Notifications</li></Link>
                                        <Link className='no-underline' to={'logout'}><li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</li></Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div
                            className='relative cursor-pointer bg-gray-100 p-2 rounded'
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <strong>Hi There</strong>
                            <Link to={'/login'} className='text-green-600 font-bold'>
                                <div className={`absolute bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                                    Login
                                </div>
                            </Link>
                        </div>
                }

            </div>
        </div>
    )
}
