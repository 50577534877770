import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className='w-full bg-white pb-2'>
            <div className='max-w-[1000px] bg-white mx-auto flex justify-between px-2 items-center'>
                <div>
                    <img className='p-1 h-12' src="https:www.whickr.com/img/whickrWordLogo.svg" alt="" />
                </div>
                <div className='hidden md:block'>

                    <ul className='flex gap-8 md:gap-16 mt-3 text-green-400'>
                        <NavLink exact to="/" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        }>
                            Marketplace
                        </NavLink>
                        <NavLink to="/Blog" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8 " : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        }>
                            Blog
                        </NavLink>
                        <NavLink to="/Aboutus" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        }>
                            About Us
                        </NavLink>
                        <NavLink to="/Contactus" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        }>
                            Contact Us
                        </NavLink>
                    </ul>
                </div>
                <div className='md:hidden'>
                    <button onClick={() => setMenuOpen(!menuOpen)} className='text-green-400'>
                        <svg xmlns="http:www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
            {menuOpen && (
                <div className='md:hidden bg-white mt-2'>
                    <ul className='flex flex-col items-center gap-4 text-green-400'>
                        <NavLink exact to="/" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        } onClick={() => setMenuOpen(false)}>
                            Marketplace
                        </NavLink>
                        <NavLink to="/Blog" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        } onClick={() => setMenuOpen(false)}>
                            Blog
                        </NavLink>
                        <NavLink to="/Aboutus" className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        } onClick={() => setMenuOpen(false)}>
                            About Us
                        </NavLink>
                        <NavLink to="/Contactus" c className={({ isActive, isPending }) =>
                            isActive ? "text-green-700 font-semibold underline-offset-8" : isPending ? "isPending" : "text-green-700 font-semibold no-underline"
                        } onClick={() => setMenuOpen(false)}>
                            Contact Us
                        </NavLink>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Header;





