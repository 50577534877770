import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'reactjs-popup/dist/index.css';
import Autocomplete from "react-google-autocomplete";
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";

function Marketplace() {

  const [highlight, setHighlight] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [Name, setName] = useState('');

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username');
    if (userEmail && userPassword) {
      setName(userName);
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  return (
    <>
      <div className='bg-gray-50'>
        <div className='container py-2 flex flex-wrap justify-between items-center'>
          <ul className='flex flex-wrap gap-4 md:gap-8'>
            {headerMenu.map((item, index) => (
              <Link key={index} to={item.path} className='no-underline'>
                <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                  <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                  <span className='text-black text-sm md:text-xl'>{item.link}</span>
                </li>
              </Link>
            ))}
          </ul>
          <div className="relative inline-block text-left">
            {showLoginPopup
              ? <div
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
              >
                <span className='text-xl md:text-3xl'><CiUser /></span>
                <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
                <p className='text-xl my-auto'><FaAngleDown /></p>
                {isOpen && (
                  <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                      <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                      <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                      <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                      <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                      <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                    </div>
                  </div>
                )}
              </div>
              : <div
                className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                <Link to={'/login'} className='text-green-600 font-bold'>
                  <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                    Login
                  </div>
                </Link>
              </div>
            }
          </div>
        </div>

        <div className='px-4 md:px-8 lg:px-16 flex flex-col md:flex-row bg-gray-50 h-full pt-4'>
          <div className='h-full max-w-full md:max-w-3xl lg:max-w-4xl xl:max-w-5xl mx-auto'>
            <div className='shadow-2xl bg-white mt-2 rounded-xl relative pb-4 border-yellow-400 border-4'>
              <img className='w-full rounded-t-xl' src="https://d4i9b05ppmspg.cloudfront.net/e816ed27-e687-4dc4-a1bf-0fdc2e8704dc-lg@1x.webp" alt="" />
              <div className='btn_like absolute right-4 top-4'>
                <img src="https://www.whickr.com/img/heartEmptyIcon.svg" className='bg-white p-2 w-10 h-10 rounded-3xl' alt="" />
              </div>
              <div className='flex flex-col md:flex-row md:justify-between p-4'>
                <div className='flex items-center'>
                  <h1 className='text-green-800 text-xl md:text-2xl'>£4,500</h1>
                  <button className='bg-[#F0EEF8] rounded-xl px-3 ml-3 text-center text-sm text-[#695BBC] cursor-pointer'>Sale</button>
                </div>
                <div className='mt-2 md:mt-0'>
                  <h2 className='text-xl font-bold'>County/HOYs standard Welsh cob</h2>
                  <p className='text-lg text-gray-600'>Joe Beckett in Malton</p>
                </div>
              </div>
              <div className='flex flex-wrap space-x-2 mt-3 px-4'>
                <h1 className='text-lg'>14.2hh</h1>
                <div className='w-0.5 h-6 bg-green-700' />
                <h6 className='text-lg'>2 years old</h6>
                <div className='w-0.5 h-6 bg-green-700' />
                <h1 className='text-lg'>Gelding</h1>
                <div className='flex-grow' />
                <div className='flex gap-1 items-center'>
                  <h1 className='text-sm'>View</h1>
                  <img className='mr-3' src="https://www.whickr.com/img/arrowRightIcon.svg" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className='w-full md:w-3/12 mt-4 md:mt-0'>
            <div className='h-28 w-full shadow-sm bg-white rounded-xl p-4'>
              <label htmlFor="sort" className='block'>Sort</label>
              <select id="sort" className='focus:outline-none border-2 w-full rounded p-2 mt-2'>
                <option value="">Newest Listed</option>
                <option value="">Oldest Listed</option>
              </select>
            </div>
            <div className='shadow-sm w-full bg-white rounded-xl mt-4 p-4 pb-6'>
              <label htmlFor="filter" className='block'>Filter</label>
              <label htmlFor="location" className='block mt-2'>Location</label>
              <Autocomplete
                className='focus:outline-none border-2 rounded-xl w-full p-2 mt-2'
                apiKey={'AIzaSyCqS-_TBx6GtHf-PSr7Ia2dNDhNVWkPPH4'}
                onPlaceSelected={(place) => { }}
              />
              <select id="distance" className='focus:outline-none border-2 rounded-xl w-full p-2 mt-2'>
                <option value="">Select</option>
                <option value="">+50 Miles</option>
                <option value="">+100 Miles</option>
                <option value="">+200 Miles</option>
                <option value="">+300 Miles</option>
                <option value="">+500 Miles</option>
              </select>
              <div className='mt-4'>
                <label htmlFor="sale-type" className='block'>Sale Type</label>
                <button onClick={() => setHighlight(0)} className='bg-gray-400 border-blue-300 py-1 px-3 rounded-xl mt-2 text-white' style={{ background: highlight === 0 ? 'green' : 'gray' }}>Sale</button>
                <button onClick={() => setHighlight(1)} className='bg-gray-400 border-blue-300 py-1 px-3 rounded-xl ml-2 text-white' style={{ background: highlight === 1 ? 'green' : 'gray' }}>Loan</button>
              </div>
              <div className='mt-4'>
                <label htmlFor="age" className='block'>Age (years)</label>
                <input id="age" className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24' placeholder='min' type="text" />
                <input className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24 ml-2' placeholder='max' type="text" />
              </div>
              <div className='mt-4'>
                <label htmlFor="height" className='block'>Height</label>
                <input id="height" className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24' placeholder='min' type="text" />
                <input className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24 ml-2' placeholder='max' type="text" />
              </div>
              <div className='mt-4'>
                <label htmlFor="gender" className='block'>Horse Type</label>
                <Multiselect
                  isObject={false}
                  options={['Horse', 'Pony']}
                />
              </div>
              <div className='mt-4'>
                <label htmlFor="gender" className='block'>Gender</label>
                <Multiselect
                  isObject={false}
                  options={['Mare', 'Gelding', 'Stallion']}
                />
              </div>
              <div className='mt-4'>
                <label htmlFor="color" className='block'>Color</label>
                <Multiselect
                  isObject={false}
                  options={[
                    'Palomino',
                    'Piebald',
                    'Roan',
                    'Rose Grey',
                    'Skewbald',
                    'Spotted',
                    'Strawberry Roan',
                    'Tri Coloured'
                  ]}
                />
              </div>
              <div className='mt-4'>
                <label htmlFor="budget" className='block'>Budget <span className='text-gray-400'>(£)</span></label>
                <input id="budget" className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24' placeholder='min' type="text" />
                <input className='p-2 rounded-xl border-blue-300 border-2 mt-2 w-24 ml-2' placeholder='max' type="text" />
              </div>
              <div className='mt-4 flex items-center'>
                <input type="checkbox" id="exclude-poa" className='p-2 border-blue-300 border-2' />
                <label htmlFor="exclude-poa" className='ml-2'>Exclude POA</label>
              </div>
              <div className='mt-4'>
                <label htmlFor="disciplines" className='block'>Disciplines</label>
                <Multiselect
                  isObject={false}
                  options={[
                    'Companion',
                    'Dressage',
                    'Driving',
                    'Endurance',
                    'Happy Hacker',
                    'Hunting',
                    'Mother Daughter Share',
                    'Polo',
                    'Pony Club',
                    'Racing',
                    'Riding Club',
                    'Show Jumping',
                    'Showing',
                    'Youngstock'
                  ]}
                />
              </div>
              <div className='mt-4 flex items-center'>
                <input type="checkbox" id="with-media-only" className='p-2 border-blue-300 border-2' />
                <label htmlFor="with-media-only" className='ml-2'>With Media Only</label>
              </div>
              <div className='mt-4 text-center'>
                <Link to="/view">
                  <button className='bg-green-800 text-white font-bold w-full md:w-52 p-2 rounded-xl'>Apply Filters</button>
                </Link>
              </div>
              <div className='mt-4 text-center'>
                <Link to="/view">
                  <button className='border-green-800 border-2 text-green-800 font-bold bg-gray-100 w-full md:w-52 p-2 rounded-xl'>Clear Filters</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Marketplace;
