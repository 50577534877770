import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Autocomplete from "react-google-autocomplete";
import { CiUser } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import axios from 'axios';

function Createwantedadvert() {

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [Name, setName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    headline: '',
    location: '',
    distance: '',
    type: '',
    discipline: '',
    priceMin: '',
    priceMax: '',
    heightMin: '',
    heightMax: '',
    description: ''
  });

  const navigate = useNavigate()

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    const userName = localStorage.getItem('username')
    if (userEmail && userPassword) {
      setName(userName)
      setShowLoginPopup(true);
    } else {
      setShowLoginPopup(false);
    }
  }, []);

  const headerMenu = [
    { icon: "https://www.whickr.com/img/browseIcon.svg", path: "/browse", link: "Browse" },
    { icon: "https://www.whickr.com/img/stableIcon.svg", path: "/stable", link: "Stable" },
    { icon: "https://www.whickr.com/img/createAdvertIcon.svg", path: "/createadvert", link: "Create Advert" },
    { icon: "https://www.whickr.com/img/createWantedAdvertIcon.svg", path: "/createwantedadvert", link: "Create Wanted Advert" },
  ];

  const checkLoginInfo = () => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');
    if (!userEmail && !userPassword) {
      navigate('/login')
    }
  }

  const validate = (values) => {
    let errors = {};
    if (!values.headline) errors.headline = "Headline is required";
    if (!values.location) errors.location = "Location is required";
    if (!values.distance) errors.distance = "Distance is required";
    if (!values.type) errors.type = "Type is required";
    if (!values.discipline) errors.discipline = "Discipline is required";
    if (!values.priceMin) errors.priceMin = "Min Price is required";
    if (!values.priceMax) errors.priceMax = "Max Price is required";
    if (!values.heightMin) errors.heightMin = "Min Height is required";
    if (!values.heightMax) errors.heightMax = "Max Height is required";
    if (!values.description) errors.description = "Description is required";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handlePlaceSelected = (place) => {
    setFormValues({
      ...formValues,
      location: place.formatted_address
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      console.log(formValues);
    }
  }, [formErrors]);

  const submitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmitting(true);

    if (Object.keys(formErrors).length === 0) {
      const formData = new FormData();
      formData.append('headline', formValues.headline);
      formData.append('location', formValues.location);
      formData.append('distance', formValues.distance);
      formData.append('type', formValues.type);
      formData.append('discipline', formValues.discipline);
      formData.append('priceMin', formValues.priceMin);
      formData.append('priceMax', formValues.priceMax);
      formData.append('heightMin', formValues.heightMin);
      formData.append('heightMax', formValues.heightMax);
      formData.append('description', formValues.description);

      axios.post('https://crm.commoditylevels.com/api/advertisements', formData)
        .then((success) => {
          console.log(success, 'success');
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }

  return (
    <>
      <div className='bg-slate-100 '>
        <div className='container py-2 flex flex-wrap justify-between items-center'>
          <ul className='flex flex-wrap gap-4 md:gap-8'>
            {headerMenu.map((item, index) => (
              <Link key={index} to={item.path} className='no-underline'>
                <li className='flex items-center gap-2 md:gap-3 hover:text-green-700 cursor-pointer'>
                  <img className='w-5 h-5 md:w-6 md:h-6' src={item.icon} alt={item.link} />
                  <span className='text-black text-sm md:text-xl'>{item.link}</span>
                </li>
              </Link>
            ))}
          </ul>
          <div className="relative inline-block text-left">
            {showLoginPopup
              ? <div
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className="cursor-pointer shadow-sm p-2 rounded-xl flex items-center gap-2"
              >
                <span className='text-xl md:text-3xl'><CiUser /></span>
                <p className='font-semibold text-sm md:text-base my-auto'>Hi {Name}</p>
                <p className='text-xl my-auto'><FaAngleDown /></p>
                {isOpen && (
                  <div className="absolute z-20 top-7 mt-2 w-48 md:w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Link to={'/myadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Adverts</Link>
                      <Link to={'/mywantedadvert'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>My Wanted Adverts</Link>
                      <Link to={'/editprofile'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Edit Profile</Link>
                      <Link to={'/changeemail'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Email</Link>
                      <Link to={'/changepassword'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Change Password</Link>
                      <Link to={'/logout'} className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>Logout</Link>
                    </div>
                  </div>
                )}
              </div>
              : <div
                className='relative cursor-pointer bg-gray-100 p-2 rounded flex items-center gap-2'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <strong className='shadow-sm px-4 py-2 rounded-xl'>Hi There</strong>
                <Link to={'/login'} className='text-green-600 font-bold'>
                  <div className={`absolute top-11 left-5 bg-white border border-gray-300 rounded mt-2 py-2 px-3 ${isHovered ? 'block' : 'hidden'}`}>
                    Login
                  </div>
                </Link>
              </div>
            }
          </div>
        </div>

      </div>
      <div className='bg-gray-100 mix-h-screen-xl flex justify-center items-center pt-4 pb-4'>

        <div className='w-full max-w-6xl px-4 '>
          <div className='flex flex-col gap-2 md:flex-row shadow-3xl p-6 rounded-lg'>
            <div className='md:w-4/12 bg-white rounded-xl p-3 md:mb-0 lg:sticky top-0'>
              <div className='flex items-center'>
                <h1 className='bg-[#377f63] w-[30px] h-[30px] flex items-center justify-center text-white rounded-full text-[18px] border border-black'>1</h1>
                <div className='mx-3'>
                  <h1 className='text-[18px]'>

                    Set your criteria </h1>
                  <div>Tell us what you're <br /> looking for.
                  </div>
                </div>
              </div>
              <div className=' mt-2'>
                <div className='flex items-center text-gray-500'>
                  <h1 className='w-[30px] h-[30px] flex items-center justify-center text-[#00000040] rounded-full text-[18px] border border-[#00000040]'>2</h1>
                  <div className='mx-3 text-[#00000072]'>
                    <h2 className='text-[18px]'>
                      Wait for matches </h2>
                    <div> We will email you any matches.
                    </div>
                  </div>
                </div>
                <div className='flex items-center text-gray-500 mt-2'>
                  <h1 className='w-[30px] h-[30px] flex items-center justify-center text-[#00000040] rounded-full text-[18px] border border-[#00000040]'>3</h1>
                  <div className='mx-3 text-[#00000072]'>
                    <h2 className='text-[18px]'>
                      Contact Sellers
                    </h2>
                    <div>
                      Get in touch with sellers to discuss
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
            <div onClick={checkLoginInfo} className='md:w-8/12 bg-white rounded-xl p-3 md:pl-6'>
              <form onClick={submitHandler}>
                <div className=''>
                  <h1 className='text-[18px] text-[000000D9]'>Let the adverts come to you!</h1>
                  <p className='text-gray-700 text-[000000D9] text-[16px]'>
                    Create a free Wanted Advert so that we can notify you of future adverts that match what
                    you're looking for. We will also be able to notify Sellers so they can reach out to you directly.
                  </p>
                </div>

                <div className='mt-3'>
                  <div className='border border-gray-300 p-2 rounded-xl'>
                    <h1 className='text-xl'>Headline</h1>
                    <input
                      type="text"
                      name="headline"
                      value={formValues.headline}
                      onChange={handleChange}
                      className='w-full p-1 focus:outline-none'
                    />
                  </div>
                  {formErrors.headline && <p className="text-red-500">{formErrors.headline}</p>}

                  <div className='border-gray-300 border mt-3 flex flex-col p-2 rounded-xl'>
                    <h1 className='text-[18px]'>Location</h1>
                    <Autocomplete
                      className='focus:outline-none'
                      apiKey={'AIzaSyCqS-_TBx6GtHf-PSr7Ia2dNDhNVWkPPH4'}
                      onPlaceSelected={handlePlaceSelected}
                    />
                  </div>
                  {formErrors.location && <p className="text-red-500">{formErrors.location}</p>}

                  <div className='flex flex-col md:flex-row gap-4 mt-3'>
                    <div className='md:w-6/12'>
                      <div className='border border-gray-300 p-3 rounded-xl'>
                        <h1 className='text-[18px]'>Distance (Miles)</h1>
                        <select
                          name="distance"
                          value={formValues.distance}
                          onChange={handleChange}
                          className='focus:outline-none'
                        >
                          <option value="">Select</option>
                          <option value="50">+50 Miles</option>
                          <option value="100">+100 Miles</option>
                          <option value="200">+200 Miles</option>
                          <option value="300">+300 Miles</option>
                          <option value="500">+500 Miles</option>
                        </select>
                      </div>
                      {formErrors.distance && <p className="text-red-500">{formErrors.distance}</p>}
                    </div>


                    <div className='md:w-6/12'>
                      <div className='border border-gray-300 p-3 rounded-xl flex items-center'>
                        <div className='w-6/12'>
                          <h1 className='text-[18px]'>Type</h1>
                          <select
                            name="type"
                            value={formValues.type}
                            onChange={handleChange}
                            className='focus:outline-none'
                          >
                            <option value="">Horse</option>
                            <option value="Poly">Poly</option>
                          </select>
                        </div>
                      </div>
                      {formErrors.type && <p className="text-red-500">{formErrors.type}</p>}
                    </div>
                  </div>

                  <div className='flex gap-4'>
                    <div className='border-gray-300 border mt-3 h-24 p-2 w-full rounded-xl'>
                      <h1 className='text-[18px]'>Discipline</h1>
                      <select
                        name="discipline"
                        value={formValues.discipline}
                        onChange={handleChange}
                        className='w-full text-[20px] font-bold focus:outline-none text-gray-400'
                      >
                        <option value="">Select One</option>
                        <option value="All Rounder text-black">All Rounder</option>
                        <option value="Broodmare text-black">Broodmare</option>
                      </select>
                    </div>
                  </div>
                  {formErrors.discipline && <p className="text-red-500">{formErrors.discipline}</p>}

                  <div className='flex justify-between flex-col md:flex-row gap-4 mt-3'>
                    <div>
                      <div className='border w-full border-gray-300 p-2 rounded-xl mb-1'>
                        <h1 className='text-xl'>Min Price</h1>
                        <div>
                          <input
                            type="number"
                            name="priceMin"
                            value={formValues.priceMin}
                            onChange={handleChange}
                            className='w-full px-1 text-[18px] font-bold focus:outline-none'
                          />
                        </div>
                      </div>
                      {formErrors.priceMin && <p className="text-red-500">{formErrors.priceMin}</p>}
                    </div>
                    <div>
                      <div className='border w-full border-gray-300 p-2 rounded-xl mb-1'>
                        <h1 className='text-xl'>Max Price</h1>
                        <input
                          type="number"
                          name="priceMax"
                          value={formValues.priceMax}
                          onChange={handleChange}
                          className='w-full px-1 text-[18px] font-bold focus:outline-none'
                        />
                      </div>
                      {formErrors.priceMax && <p className="text-red-500">{formErrors.priceMax}</p>}
                    </div>
                  </div>

                  {/* <div className='border border-gray-300 p-2 rounded-xl mb-2 mt-2'>
                    <h1 className='text-xl'>Include POA</h1>
                    <select
                      name="poa"
                      value={formValues.poa}
                      onChange={handleChange}
                      className='w-full text-[20px] font-bold focus:outline-none text-gray-400'
                    >
                      <option value="">Select One</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {formErrors.poa && <p className="text-red-500">{formErrors.poa}</p>} */}

                  <div className='flex justify-between flex-col md:flex-row gap-4 mt-3'>
                    <div>
                      <div className='border w-full border-gray-300 p-2 rounded-xl'>
                        <h1 className='text-xl'>Min Height</h1>
                        <input
                          type="number"
                          name="heightMin"
                          value={formValues.heightMin}
                          onChange={handleChange}
                          placeholder='15.2'
                          className='w-full px-1 text-[18px] font-bold focus:outline-none'
                        />
                      </div>
                      {formErrors.heightMin && <p className="text-red-500">{formErrors.heightMin}</p>}
                    </div>
                    <div>
                      <div className='border w-full border-gray-300 p-2 rounded-xl'>
                        <h1 className='text-xl'>Max Height</h1>
                        <input
                          type="number"
                          name="heightMax"
                          value={formValues.heightMax}
                          onChange={handleChange}
                          placeholder='15.2'
                          className='w-full px-1 text-[18px] font-bold focus:outline-none'
                        />
                      </div>
                      {formErrors.heightMax && <p className="text-red-500">{formErrors.heightMax}</p>}
                    </div>
                  </div>

                  <div className='border-gray-300 border-2 p-2 mt-3 h-40 rounded-xl'>
                    <h2 className='text-[16px]'>Description</h2>
                    <textarea
                      name="description"
                      value={formValues.description}
                      onChange={handleChange}
                      className='w-full h-[100px] p-1 text-[16px] focus:outline-none resize-none overflow-auto'
                      placeholder="Enter description here..."
                    />
                  </div>
                  {formErrors.description && <p className="text-red-500">{formErrors.description}</p>}

                  <div className='flex justify-center md:justify-end mt-3'>
                    <button type='submit' className='bg-green-800 text-white p-3 rounded-xl font-bold w-full md:w-56'>
                      Publish Wanted Advert
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>

  )
}

export default Createwantedadvert;
